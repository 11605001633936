import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type ComponentPropsWithRef,
	type FormEventHandler,
	type JSX,
	type MouseEventHandler
} from "react"
import { useNavigate } from "react-router-dom"

import { useCraftSection } from "~/hooks/craft/use-craft-section"
import { useRouteCraftSite } from "~/hooks/router/use-route-craft-site"
import { useRouterState } from "~/hooks/use-router-state"
import { setSearchVisibility } from "~/state/slices/layout"
import { useReduxDispatch } from "~/state/store"
import { CraftSeverity } from "~/types/api/craft/fields/severity"
import type { CraftEntryState, SelectRoleState } from "~/types/state"

import FamilyIcon from "~/assets/icons/family.svg?react"
import HospitalIcon from "~/assets/icons/hospital.svg?react"
import InfoIcon from "~/assets/icons/info.svg?react"
import SearchIcon from "~/assets/icons/search.svg?react"
import StethoscopeHeartIcon from "~/assets/icons/stethoscope-heart.svg?react"
import { useCraftSearch, type SearchableCraftEntryTypes } from "~/hooks/craft/use-craft-search"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const MAXIMUM_VISIBLE_SEARCH_RESULTS = import.meta.env.VITE_MAXIMUM_VISIBLE_SEARCH_RESULTS
if (MAXIMUM_VISIBLE_SEARCH_RESULTS === undefined || isNaN(MAXIMUM_VISIBLE_SEARCH_RESULTS))
	throw new Error("The maximum visible search results is not set!")

const SearchResult = ({
	entry
}: ComponentPropsWithRef<"div"> & {
	entry: SearchableCraftEntryTypes
}): JSX.Element => {
	const navigate = useNavigate()

	const craftSite = useRouteCraftSite()

	const { section } = useCraftSection({
		siteId: craftSite?.id ?? null,
		sectionId: entry.section
	})

	const onClick = useCallback<MouseEventHandler<HTMLParagraphElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		navigate(entry.url, {
			state: {
				target: {
					site: craftSite.id,
					section: entry.section,
					entry: entry.id,
					url: entry.url
				}
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, craftSite, entry])

	/* eslint-disable no-nested-ternary */
	const iconClasses = `-mt-1 h-4 w-4 min-w-4 min-h-4 max-w-4 max-h-4 ${entry.fields.severity === CraftSeverity.Red ? "fill-algorithm-red" : entry.fields.severity === CraftSeverity.Amber ? "fill-algorithm-amber" : entry.fields.severity === CraftSeverity.Green ? "fill-algorithm-green" : ""}`

	if (section === null) return <></>

	return (
		<div className="flex flex-row items-center gap-x-2">
			{section.handle === "parents" ? (
				<FamilyIcon className={iconClasses} />
			) : section.handle === "professionals" ? (
				<StethoscopeHeartIcon className={iconClasses} />
			) : section.handle === "hospital" ? (
				<HospitalIcon className={iconClasses} />
			) : section.handle === "about" ? (
				<InfoIcon className={iconClasses} />
			) : (
				<></>
			)}

			<p
				onClick={onClick}
				className={`overflow-hidden text-ellipsis whitespace-nowrap hover:cursor-pointer hover:underline ${entry.fields.severity === CraftSeverity.Red ? "text-algorithm-red" : entry.fields.severity === CraftSeverity.Amber ? "text-algorithm-amber" : entry.fields.severity === CraftSeverity.Green ? "text-algorithm-green" : ""}`.trimEnd()}
				x-analytics-is-search-result={entry.slug} // Required for tracking whether the navigation was from a search result
			>
				{entry.title}
			</p>
		</div>
	)
}

/**
 * The search bar & results.
 * @returns The React component.
 * @example <Search />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
const Search = ({ ...props }: ComponentPropsWithRef<"div">): JSX.Element => {
	const craftSite = useRouteCraftSite()

	const routerState = useRouterState<SelectRoleState>()

	const searchBarReference = useRef<HTMLDivElement>(null)
	const searchInputReference = useRef<HTMLInputElement>(null)
	const searchResultsReference = useRef<HTMLDivElement>(null)

	const { entries, isReady } = useCraftSearch({
		siteId: craftSite?.id ?? null
	})

	const [searchState, setSearchState] = useState<{
		isSearching: boolean
		searchResults: SearchableCraftEntryTypes[] | null
	}>({
		isSearching: false,
		searchResults: null
	})

	const dispatch = useReduxDispatch()

	useEffect(() => {
		dispatch(setSearchVisibility(true))

		return () => {
			dispatch(setSearchVisibility(false))
		}
	}, [dispatch])

	// Updates the search results when the search query changes...
	const onSearchInput = useCallback<FormEventHandler<HTMLInputElement>>(
		(event): void => {
			if (entries === null) return

			const searchQuery = event.currentTarget.value.toLowerCase().trim()

			setSearchState(() => {
				// Clear when empty
				if (searchQuery === "")
					return {
						isSearching: false,
						searchResults: null
					}

				return {
					isSearching: searchQuery.length > 0,
					searchResults: entries.filter(
						// Match title OR slug
						({ title, slug }) =>
							title.toLowerCase().includes(searchQuery) || slug.toLowerCase().includes(searchQuery)
					)
				}
			})
		},
		[entries]
	)

	// Clears the search results when the user clicks outside the search bar...
	const onWindowClick = useCallback((event: MouseEvent) => {
		// Do not continue if the click target is the search/results
		if (
			searchBarReference.current?.contains(event.target as Node) === true ||
			searchResultsReference.current?.contains(event.target as Node) === true
		)
			return

		setSearchState(() => ({
			isSearching: false,
			searchResults: []
		}))

		const searchInput = searchInputReference.current
		if (searchInput === null) return

		searchInput.value = ""
		searchInput.blur()
	}, [])

	const visibleSearchResults = useMemo<SearchableCraftEntryTypes[]>(
		() => searchState.searchResults?.slice(0, MAXIMUM_VISIBLE_SEARCH_RESULTS) ?? [],
		[searchState]
	)
	const overflowSearchResults = useMemo<SearchableCraftEntryTypes[]>(
		() => searchState.searchResults?.slice(MAXIMUM_VISIBLE_SEARCH_RESULTS) ?? [],
		[searchState]
	)

	useEffect(() => {
		if (!searchState.isSearching) return

		window.addEventListener("click", onWindowClick)

		return () => {
			window.removeEventListener("click", onWindowClick)
		}
	}, [searchState, onWindowClick])

	return (
		<div
			{...props}
			className={`m-1 flex flex-col fill-logo-purple text-logo-purple ${props.className ?? ""}`.trimEnd()}>
			<div
				ref={searchBarReference}
				className={`flex flex-row items-center gap-x-3 border border-control-border bg-control-background ${searchState.isSearching ? "rounded-t-2xl" : "rounded-full"} ${!isReady || entries === null ? "pointer-events-none cursor-not-allowed" : ""}`.trimEnd()}>
				<SearchIcon
					width={24}
					height={24}
					className={`m-3 me-0 aspect-square ${!isReady || entries === null ? "fill-control-disabled-text" : "fill-logo-purple"}`}
				/>
				<input
					ref={searchInputReference}
					type="text"
					placeholder={isReady && entries !== null ? "Search..." : "Finding searchable pages..."}
					className="mt-1 h-full w-full bg-transparent outline-none"
					onInput={onSearchInput}
					autoFocus={routerState?.shouldFocusSearchBar ?? false}
					disabled={!isReady || entries === null}
				/>
			</div>

			{searchState.isSearching && (
				<div ref={searchResultsReference} className="fixed mt-12 w-[calc(100%-3.5rem)]">
					<div className="flex flex-col gap-y-2 rounded-b-2xl border border-control-border bg-control-background p-3 px-4">
						{visibleSearchResults.map(entry => (
							<SearchResult key={entry.id} entry={entry} />
						))}

						{overflowSearchResults.length > 1 && (
							<p className="text-gray-400">
								...and {overflowSearchResults.length.toString()} more. Please refine your search.
							</p>
						)}

						{visibleSearchResults.length === 0 && overflowSearchResults.length === 0 && (
							<p className="text-gray-400">
								Sorry, we couldn't find any pages matching those terms. Please try a different search.
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default Search
