/**
 * The value from a Craft severity field.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftSeverity {
	Green = 1,
	Amber = 2,
	Red = 3
}
