import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import { orNull } from "~/helpers/primitives"
import type { CraftSection } from "~/types/api/craft/models/section"
import type { CraftSite } from "~/types/api/craft/models/site"

/**
 * Keys for persisting selections in the browser's local storage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum LocalStorageKeys {
	// Change these only if the structure of the data changes, as it will invalidate existing user's selections!
	CraftSiteSelectionJSON = "craft-site-selection-json",
	CraftSectionSelectionJSON = "craft-section-selection-json"
}

/**
 * The user's selections.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface Selections {
	/**
	 * The user's Craft site selection.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	craftSite: CraftSite | null

	/**
	 * The user's Craft section selection.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	craftSection: CraftSection | null
}

/**
 * A Redux slice for storing the user's selections.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const slice = createSlice({
	name: "selections",
	initialState: (): Selections => {
		const craftSiteJSON = orNull(window.localStorage.getItem(LocalStorageKeys.CraftSiteSelectionJSON.valueOf()))
		const craftSectionJSON = orNull(
			window.localStorage.getItem(LocalStorageKeys.CraftSectionSelectionJSON.valueOf())
		)
		if (craftSiteJSON === null || craftSectionJSON === null)
			return {
				craftSite: null,
				craftSection: null
			}

		const craftSite = JSON.parse(craftSiteJSON) as CraftSite
		const craftSection = JSON.parse(craftSectionJSON) as CraftSection

		return {
			craftSite,
			craftSection
		}
	},
	reducers: {
		updateSite: (state, action: PayloadAction<Selections["craftSite"]>): Selections => ({
			...state,
			craftSite: action.payload
		}),
		updateSection: (state, action: PayloadAction<Selections["craftSection"]>): Selections => ({
			...state,
			craftSection: action.payload
		})
	}
})

/**
 * A slice action for updating the user's Craft site selection.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const { updateSite } = slice.actions

/**
 * A slice action for updating the user's Craft section selection.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const { updateSection } = slice.actions

/**
 * The slice's reducer for the user's selections.
 * This should be added to the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export default slice.reducer
