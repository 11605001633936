import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useFetchSitesQuery } from "~/api/client"
import type { CraftSite } from "~/types/api/craft/models/site"

/**
 * Fetches all Craft sites.
 * @param params The parameters.
 * @param params.skip Whether to skip fetching Craft sites.
 * @returns The sites & API request status.
 * @example const { sites } = useCraftSites()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftSites = ({
	skip = false
}: {
	skip?: boolean
}): {
	sites: CraftSite[] | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { data, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useFetchSitesQuery(undefined, {
		skip
	})

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	return {
		sites: data ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
