import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { captureMessage } from "@sentry/react"
import { useCallback, useEffect, type JSX } from "react"
import { useNavigate } from "react-router-dom"

import BackgroundImage from "~/components/background-image"
import ContentCard from "~/components/content-card"
import Metadata from "~/components/metadata"
import QuickNavigation from "~/components/quick-navigation"
import Anchor from "~/components/standard/anchor"
import Button from "~/components/standard/button"
import { BackgroundImagePath } from "~/images"
import { Routes } from "~/router/routes"
import { isBaseQueryError, isSerializedError } from "~/types/error/query"
import { isRouteError, type RouteError } from "~/types/error/router"

import WarningIcon from "~/assets/icons/warning.svg?react"

/**
 * Shows a nicely formatted error message with contact details.
 * This automatically reports the error to Sentry. Sentry capturing is disabled while in development mode.
 * @param params The parameters.
 * @param params.heading The title of the page & heading for the content block.
 * @param params.message The message within the content block.
 * @param params.backgroundImage The background image to use. If not provided, a default image is used.
 * @param params.level The severity of the error.
 * @param params.error An error to report.
 * @returns The React component.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.2
 */
const InnerError = ({
	heading = "Error",
	message = "Sorry, we're having technical difficulties at the moment. Please try again later.",

	backgroundImage,

	level = "warning",
	error
}: {
	heading?: string
	message?: string

	backgroundImage?: JSX.Element | (() => JSX.Element)

	level?: "debug" | "error" | "fatal" | "info" | "warning"
	error?: Error | FetchBaseQueryError | RouteError | SerializedError | null
}): JSX.Element => {
	const navigate = useNavigate()

	let extra = {}
	if (isBaseQueryError(error))
		extra = {
			status: error.status,
			data: error.data
		}
	else if (isSerializedError(error))
		extra = {
			name: error.name,
			message: error.message,
			code: error.code,
			stack: error.stack
		}
	else if (isRouteError(error))
		extra = {
			status: error.status,
			statusText: error.statusText,
			data: error.data
		}
	else if (error instanceof Error)
		extra = {
			name: error.name,
			message: error.message,
			stack: error.stack,
			cause: error.cause
		}

	const onHomeClick = useCallback(() => {
		navigate(Routes.Index)
	}, [navigate])

	useEffect(() => {
		console.warn(`${heading}: ${message}`, extra)

		captureMessage(`${heading}: ${message}`, {
			level,
			extra
		})
	})

	return (
		<>
			<Metadata title={heading} shouldIndex={false} />

			{/* Feature */}
			{typeof backgroundImage === "function"
				? backgroundImage()
				: (backgroundImage ?? (
						<BackgroundImage
							imageUrl={BackgroundImagePath.ChildWithSymptoms}
							accessibilityDescription="A parent checking on a child that has symptoms."
						/>
					))}

			{/* Card */}
			<ContentCard
				heading={heading}
				icon={<WarningIcon width={32} height={32} className="fill-white" />}
				//dynamicHeight={true}
				innerClassName="justify-between">
				<div className="flex flex-1 flex-col gap-y-4 text-center">
					<p>{message}</p>
					<p>
						If this problem persists, you can contact us to report it by emailing{" "}
						<Anchor href="mailto:handi@somersetft.nhs.uk">handi@somersetft.nhs.uk</Anchor>.
					</p>
					<Button
						label="Home"
						onClick={onHomeClick}
						className="justify-center rounded-xl bg-purple-alt/15 p-3 px-4 text-logo-purple hover:bg-purple-alt/25 active:bg-purple-alt/35"
					/>
				</div>

				<QuickNavigation />
			</ContentCard>
		</>
	)
}

export default InnerError
