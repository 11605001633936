import { useCallback, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"

import Button from "~/components/standard/button"
import { CraftMapContentBlockLocation, type CraftMapContentBlock } from "~/types/api/craft/fields/content-blocks/map"

import MapIcon from "~/assets/icons/map.svg?react"

const GOOGLE_MAPS_SEARCH_BASE_URL = "https://www.google.com/maps/search/?api=1"

/**
 * A content block that renders a proximity map.
 * @returns A React component.
 * @example <ProximityMapContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ProximityMapContentBlock = ({
	block,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "label" | "onClick"> & { block: CraftMapContentBlock }): JSX.Element => {
	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		// eslint-disable-next-line default-case
		switch (block.location) {
			case CraftMapContentBlockLocation.Doctors:
				window.open(`${GOOGLE_MAPS_SEARCH_BASE_URL}&query=Doctors+near+me`, "_blank", "noopener noreferrer")
				break
			case CraftMapContentBlockLocation.Dentists:
				window.open(`${GOOGLE_MAPS_SEARCH_BASE_URL}&query=Dentists+near+me`, "_blank", "noopener noreferrer")
				break
			case CraftMapContentBlockLocation.HealthProfessionals:
				window.open(
					`${GOOGLE_MAPS_SEARCH_BASE_URL}&query=Health+professionals+near+me`,
					"_blank",
					"noopener noreferrer"
				)
				break
			case CraftMapContentBlockLocation.Hospitals:
				window.open(`${GOOGLE_MAPS_SEARCH_BASE_URL}&query=Hospitals+near+me`, "_blank", "noopener noreferrer")
				break
		}
	}, [block])

	return (
		<Button
			{...props}
			label={block.heading ?? "Open map to nearby facilities"}
			icon={
				<MapIcon className="aspect-square h-9 min-h-9 w-9 min-w-9 rounded-full bg-logo-purple fill-white p-1.5" />
			}
			onClick={onClick}
			className="items-center gap-x-4 rounded-xl bg-purple-alt/15 p-4 text-left text-logo-purple hover:bg-purple-alt/25 active:bg-purple-alt/35"
		/>
	)
}

export default ProximityMapContentBlock
