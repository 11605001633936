import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useResolveEntryQuery } from "~/api/client"
import { NEVER_CRAFT_HANDLE } from "~/constants"
import { isCraftEntry } from "~/types/api/craft/models/entry"
import type { CraftAboutEntry } from "~/types/api/craft/sections/about"
import type { CraftHomeEntry } from "~/types/api/craft/sections/home"
import type { CraftHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftProfessionalsEntry } from "~/types/api/craft/sections/professionals"

/**
 * Resolves an individual Craft entry from a Craft site handle, Craft section handle, and Craft entry slug.
 * @param params The parameters.
 * @param params.siteHandle The handle of the Craft site to fetch the entry within.
 * @param params.sectionHandle The handle of the Craft section to fetch the entry within.
 * @param params.entrySlug The slug of the Craft entry to fetch.
 * @param params.skip Whether to skip fetching the Craft section.
 * @returns The Craft entry & API request status.
 * @example const { entry } = useCraftResolveEntry()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftResolveEntry = <
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
	EntryType extends
		| CraftAboutEntry
		| CraftHomeEntry
		| CraftHospitalEntry
		| CraftParentsEntry
		| CraftProfessionalsEntry
>({
	siteHandle,
	sectionHandle,
	entrySlug,

	skip = false
}: {
	siteHandle: string | null
	sectionHandle: string | null
	entrySlug: string | null

	skip?: boolean
}): {
	entry: EntryType | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { data, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useResolveEntryQuery(
		{
			siteHandle: siteHandle ?? NEVER_CRAFT_HANDLE,
			sectionHandle: sectionHandle ?? NEVER_CRAFT_HANDLE,
			entrySlug: entrySlug ?? NEVER_CRAFT_HANDLE
		},
		{
			skip: skip || siteHandle === null || sectionHandle === null || entrySlug === null
		}
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	if (data !== undefined && !isCraftEntry(data))
		throw new Error("The resolved entry is not a Craft entry! Did we follow the redirect?")

	return {
		entry: (data as EntryType | undefined) ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
