import { useCallback, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import { useCraftAboutEntry } from "~/hooks/craft/use-craft-about-entry"
import { useCraftSymptomsEntry } from "~/hooks/craft/use-craft-symptoms-entry"
import { useRouteCraftSection } from "~/hooks/router/use-route-craft-section"
import { useRouteCraftSite } from "~/hooks/router/use-route-craft-site"
import { useMediaQueries } from "~/hooks/use-media-query"
import type { CraftEntryState } from "~/types/state"

import InfoIcon from "~/assets/icons/info.svg?react"
import PractitionersIcon from "~/assets/icons/practitioners.svg?react"
import SymptomsIcon from "~/assets/icons/symptoms.svg?react"

/**
 * The standard quick navigation bar for the application.
 * This includes quick actions for changing trust, returning to the symptoms page, and viewing trust information.
 * @returns The React component.
 * @example <QuickNavigation />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const QuickNavigation = ({ ...props }: ComponentPropsWithRef<"div">): JSX.Element => {
	const { isLandscape, isMediumWidth } = useMediaQueries()

	const navigate = useNavigate()

	const craftSite = useRouteCraftSite()
	const craftSection = useRouteCraftSection(craftSite)

	const { entry: symptomsEntry } = useCraftSymptomsEntry({
		siteId: craftSite?.id ?? null,
		sectionId: craftSection?.id ?? null,
		skip: craftSection?.handle === "about" || craftSection?.handle === "home"
	})

	const { entry: aboutEntry } = useCraftAboutEntry({
		siteId: craftSite?.id ?? null
	})

	// Navigates to the select trust page...
	const onChangeRoleClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		navigate(craftSite.url)
	}, [navigate, craftSite])

	// Navigates to the symptoms page...
	const onSymptomsClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		if (symptomsEntry === null) {
			console.warn(`Could not find the symptoms Craft entry!`)
			return
		}

		navigate(symptomsEntry.url, {
			state: {
				target: {
					site: craftSite.id,
					section: symptomsEntry.section,
					entry: symptomsEntry.id,
					url: symptomsEntry.url
				}
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, craftSite, symptomsEntry])

	// Navigates to the about page (same one accessible from settings page)...
	const onAboutTrustClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		if (aboutEntry === null) {
			console.warn("Could not find the About Craft CMS entry!")
			return
		}

		// Special case where about is a "role"
		navigate(aboutEntry.url, {
			state: {
				target: {
					site: craftSite.id,
					section: aboutEntry.section,
					entry: aboutEntry.id,
					url: aboutEntry.url
				}
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, craftSite, aboutEntry])

	return (
		<div
			{...props}
			className={`sticky bottom-0 m-auto flex flex-row justify-between rounded-full border border-control-border bg-white shadow-sm ${isLandscape === true ? "w-1/2 p-3" : `p-4 ${isMediumWidth === true ? "mx-2" : "w-full"}`} ${props.className ?? ""}`.trimEnd()}>
			<PractitionersIcon
				className={`aspect-square overflow-visible rounded-full p-3 duration-hover ${craftSite !== null ? "bg-purple-alt/20 fill-logo-purple hover:cursor-pointer hover:bg-purple-alt/30 active:bg-purple-alt/40" : "cursor-not-allowed bg-control-disabled-background fill-control-disabled-text"}`}
				width={48}
				height={48}
				onClick={onChangeRoleClick}
			/>

			<SymptomsIcon
				className={`aspect-square overflow-visible rounded-full p-3 duration-hover ${symptomsEntry !== null ? "bg-purple-alt/20 fill-logo-purple hover:cursor-pointer hover:bg-purple-alt/30 active:bg-purple-alt/40" : "cursor-not-allowed bg-control-disabled-background fill-control-disabled-text"}`.trimEnd()}
				width={48}
				height={48}
				onClick={onSymptomsClick}
			/>

			<InfoIcon
				className={`aspect-square rounded-full p-3 duration-hover ${aboutEntry !== null ? "bg-purple-alt/20 fill-logo-purple hover:cursor-pointer hover:bg-purple-alt/30 active:bg-purple-alt/40" : "cursor-not-allowed bg-control-disabled-background fill-control-disabled-text"}`.trimEnd()}
				width={48}
				height={48}
				onClick={onAboutTrustClick}
			/>
		</div>
	)
}

export default QuickNavigation
