import { SDK_VERSION as sentryVersion } from "@sentry/react"
import { SDK_VERSION as firebaseVersion } from "firebase/app"
import { version as reactVersion } from "react"

import { orNull } from "~/helpers/primitives"

// Ensure the relevant environment variables are set - https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const BASE_URL = import.meta.env.VITE_BASE_URL
if (BASE_URL === undefined || BASE_URL === "") throw new Error("The environment variable 'VITE_BASE_URL' is empty!")

/**
 * Whether the app is running as a deployed build.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const IS_DEPLOYED = import.meta.env.PROD

/**
 * Whether the app is running on the staging environment.
 * @see IS_DEPLOYED
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.2.0
 */
export const IS_STAGING =
	IS_DEPLOYED && (BASE_URL.startsWith("staging.") || window.location.hostname.startsWith("staging."))

/**
 * Whether the app is running on the production environment.
 * @see IS_DEPLOYED
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.2.0
 */
export const IS_PRODUCTION =
	IS_DEPLOYED && !BASE_URL.startsWith("staging.") && !window.location.hostname.startsWith("staging.")

/**
 * The name of the environment the app is running in.
 * @example "production"
 * @see IS_PRODUCTION
 * @see IS_STAGING
 * @see IS_DEPLOYED
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.2.0
 */
// eslint-disable-next-line no-nested-ternary
export const ENVIRONMENT_NAME = IS_PRODUCTION ? "production" : IS_STAGING ? "staging" : "development"

/**
 * The version of React.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.2.0
 */
export const REACT_VERSION = orNull(reactVersion.split("-", 2)[0])

/**
 * The version of Vite.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.2.0
 */
// export const VITE_VERSION = orNull(viteVersion)

/**
 * The version of Sentry.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.2.0
 */
export const SENTRY_VERSION = orNull(sentryVersion)

/**
 * The version of Firebase.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.2.0
 */
export const FIREBASE_VERSION = orNull(firebaseVersion)

/**
 * The identifier of a Craft element that doesn't exist and should never be seen. This purely exists to please TypeScript.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const NEVER_CRAFT_ID = 20240410

/**
 * The handle of a Craft element that doesn't exist and should never be seen. This purely exists to please TypeScript.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const NEVER_CRAFT_HANDLE = "you-should-never-see-this"
