import { type CraftContentBlockBase, CraftContentBlockType } from "~/types/api/craft/fields/content-blocks"
import type { CraftAsset } from "~/types/api/craft/models/asset"

/**
 * Represents a Craft multiple images content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftMultipleImagesContentBlock extends CraftContentBlockBase {
	type: CraftContentBlockType.MultipleImages

	/**
	 * The images within this multiple images block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	images: Array<{
		/**
		 * The heading for this asset.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		heading: string | null

		/**
		 * The caption for this asset.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		content: string | null

		/**
		 * The asset itself.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		asset: CraftAsset | null
	}>
}

/**
 * Ensures that a value is a Craft multiple images content block.
 * @param value The value to check.
 * @returns Whether the value is a Craft multiple images content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftMultipleImagesContentBlock = (value: unknown): value is CraftMultipleImagesContentBlock =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftContentBlockType.MultipleImages.valueOf() &&
	"images" in value &&
	Array.isArray(value.images)
