import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useCraftResolveSection } from "~/hooks/craft/use-craft-resolve-section"
import { useRouteParameters } from "~/hooks/router/use-route-parameters"
import { useCraftSectionSelection, useCraftSelectionsDispatch } from "~/hooks/use-selections"
import type { CraftSection } from "~/types/api/craft/models/section"
import type { CraftSite } from "~/types/api/craft/models/site"

/**
 * Fetches the current Craft section using the URL parameters.
 * This will automatically update the Craft section in the Redux store.
 * This will automatically redirect to the Select Role page if an invalid Craft section is detected, or if the URL parameters are missing.
 * @param craftSite The Craft site to fetch the section within. Obtain this via useRouteCraftSite().
 * @returns The current Craft section, or null if the relevant URL parameters are not present, or the Craft section does not exist.
 * @example const craftSection = useRouteCraftSection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useRouteCraftSection = (craftSite: CraftSite | null): CraftSection | null => {
	const navigate = useNavigate()
	const { craftSectionHandleParameter } = useRouteParameters()

	// Read the current Craft section from the Redux store
	const sectionInStore = useCraftSectionSelection()
	const { updateCraftSectionSelection, clearCraftSectionSelection } = useCraftSelectionsDispatch()

	// Read the Craft entry target from the React Router state
	// const routerState = useRouterState<CraftEntryState>()
	// const routerStateTarget = useMemo(() => routerState?.target ?? null, [routerState])

	// Fetch the Craft section that matches the URL parameter, only if the Craft section in Redux doesn't match
	const { section: resolvedSection, isReady: isResolvedSectionReady } = useCraftResolveSection({
		siteHandle: craftSite?.handle ?? null,
		sectionHandle: craftSectionHandleParameter,
		skip: sectionInStore !== null && sectionInStore.handle === craftSectionHandleParameter
	})

	// Fetch the Craft section that matches the React Router state
	// const { section: routerStateSection } = useCraftSection({
	// 	siteId: routerStateTarget?.site ?? null,
	// 	sectionId: routerStateTarget?.section ?? null
	// })

	// If there's no Craft section handle in the URL then send the user to the Select Role page for their Craft site
	useEffect(() => {
		if (craftSectionHandleParameter !== null) return
		if (craftSite === null) return

		clearCraftSectionSelection()
		navigate(craftSite.url)
	}, [navigate, clearCraftSectionSelection, craftSectionHandleParameter, craftSite])

	// If the Craft section handle in the URL doesn't exist then send the user to the Select Role page for their Craft site
	useEffect(() => {
		if (craftSectionHandleParameter === null) return
		if (!isResolvedSectionReady || resolvedSection !== null) return
		if (craftSite === null) return

		clearCraftSectionSelection()
		navigate(craftSite.url)
	}, [
		navigate,
		clearCraftSectionSelection,
		craftSectionHandleParameter,
		isResolvedSectionReady,
		resolvedSection,
		craftSite
	])

	// If the Craft section handle in the URL is different to the Redux store then update it
	useEffect(() => {
		if (craftSectionHandleParameter === null) return
		if (!isResolvedSectionReady || resolvedSection === null) return
		if (sectionInStore !== null && sectionInStore.id === resolvedSection.id) return

		updateCraftSectionSelection(resolvedSection)
	}, [
		updateCraftSectionSelection,
		craftSectionHandleParameter,
		isResolvedSectionReady,
		resolvedSection,
		// routerStateSection,
		sectionInStore
	])

	// If the Craft section handle in the URL is different to the React Router state then update it
	// useEffect(() => {
	// 	if (craftSectionHandleParameter === null) return
	// 	if (sectionInStore !== null && sectionInStore.id === routerStateSection.id) return
	// 	if (routerStateSection === null) return

	// 	updateCraftSectionSelection(routerStateSection)
	// }, [updateCraftSectionSelection, craftSectionHandleParameter, routerStateSection, sectionInStore])

	return sectionInStore
}
