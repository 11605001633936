import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useFetchEntryQuery } from "~/api/client"
import { NEVER_CRAFT_ID } from "~/constants"
import { useCraftLivePreview } from "~/hooks/craft/use-craft-live-preview"
import type { CraftAboutEntry } from "~/types/api/craft/sections/about"
import type { CraftHomeEntry } from "~/types/api/craft/sections/home"
import type { CraftHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftProfessionalsEntry } from "~/types/api/craft/sections/professionals"

/**
 * Fetches an individual Craft entry within a Craft section within a Craft site.
 * This will be a full Craft entry! Use the useCraftEntries() React hook to fetch partial Craft entries.
 * @param params The parameters.
 * @param params.siteId The identifier of the Craft site to fetch the entry within.
 * @param params.sectionId The identifier of the Craft section to fetch the entry within.
 * @param params.entryId The identifier of the Craft entry to fetch.
 * @param params.skip Whether to skip fetching the entry.
 * @returns The entry & API request status.
 * @example const { entry } = useCraftEntry()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftEntry = <
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
	EntryType extends
		| CraftAboutEntry
		| CraftHomeEntry
		| CraftHospitalEntry
		| CraftParentsEntry
		| CraftProfessionalsEntry =
		| CraftAboutEntry
		| CraftHomeEntry
		| CraftHospitalEntry
		| CraftParentsEntry
		| CraftProfessionalsEntry
>({
	siteId,
	sectionId,
	entryId,

	skip = false
}: {
	siteId: number | null
	sectionId: number | null
	entryId: number | null

	skip?: boolean
}): {
	entry: EntryType | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const livePreview = useCraftLivePreview()

	const { data, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useFetchEntryQuery(
		{
			siteId: siteId ?? NEVER_CRAFT_ID,
			sectionId: sectionId ?? NEVER_CRAFT_ID,
			entryId: entryId ?? NEVER_CRAFT_ID,

			livePreview
		},
		{
			skip: skip || siteId === null || sectionId === null || entryId === null
		}
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	return {
		entry: (data as EntryType | undefined) ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
