import { type CraftContentBlockBase, CraftContentBlockType } from "~/types/api/craft/fields/content-blocks"
import type { CraftLink } from "~/types/api/craft/link"
import type { CraftAsset } from "~/types/api/craft/models/asset"

/**
 * The styles of Craft links content blocks.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftLinksContentBlockStyle {
	Large = 1
}

/**
 * The styles of an individual link within a Craft links content block.
 * This is identical to the text content block styles, but is kept separate for clarity.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftLinksContentBlockLinkStyle {
	Highlight = 1,

	Green = 2,
	Amber = 3,
	Red = 4
}

/**
 * Represents a Craft links content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftLinksContentBlock extends CraftContentBlockBase {
	type: CraftContentBlockType.Links

	/**
	 * Whether this links block should be displayed as a card or not.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	card: boolean

	/**
	 * The style of this links block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	style: CraftLinksContentBlockStyle | null

	/**
	 * The icons within this icons block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	links: Array<{
		/**
		 * The style of this link.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		style: CraftLinksContentBlockLinkStyle | null

		/**
		 * The icon asset.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		icon: CraftAsset | null

		/**
		 * The action for this link.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		link: CraftLink
	}>
}

/**
 * Ensures that a value is a Craft links content block.
 * @param value The value to check.
 * @returns Whether the value is a Craft links content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftLinksContentBlock = (value: unknown): value is CraftLinksContentBlock =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftContentBlockType.Links.valueOf() &&
	"card" in value &&
	"style" in value &&
	"links" in value
