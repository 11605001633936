import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useCraftResolveSite } from "~/hooks/craft/use-craft-resolve-site"
import { useRouteParameters } from "~/hooks/router/use-route-parameters"
import { useCraftSelectionsDispatch, useCraftSiteSelection } from "~/hooks/use-selections"
import { Routes } from "~/router/routes"
import type { CraftSite } from "~/types/api/craft/models/site"

/**
 * Fetches the current Craft site using the URL parameters.
 * This will automatically update the Craft site in the Redux store.
 * This will automatically redirect to the Select Trust page if an invalid Craft site is detected, or if the URL parameters are missing.
 * @returns The current Craft site, or null if the relevant URL parameters are not present, or the Craft site does not exist.
 * @example const craftSite = useRouteCraftSite()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useRouteCraftSite = (shouldRedirect = true): CraftSite | null => {
	const navigate = useNavigate()
	const { craftSiteHandleParameter } = useRouteParameters()

	// Read the current Craft site from the Redux store
	const siteInStore = useCraftSiteSelection()
	const { updateCraftSiteSelection, clearCraftSiteSelection } = useCraftSelectionsDispatch()

	// Read the Craft entry target from the React Router state
	// const routerState = useRouterState<CraftEntryState>()
	// const routerStateTarget = useMemo(() => routerState?.target ?? null, [routerState])

	// Fetch the Craft site that matches the URL parameter, only if the Craft site in Redux doesn't match
	const { site: fetchedSite, isReady: isFetchedSiteReady } = useCraftResolveSite({
		siteHandle: craftSiteHandleParameter,
		skip: siteInStore !== null && siteInStore.handle === craftSiteHandleParameter
	})

	// If there's no Craft site handle in the URL then send the user to the Select Trust page
	useEffect(() => {
		if (craftSiteHandleParameter !== null) return

		clearCraftSiteSelection()
		if (shouldRedirect) navigate(Routes.SelectTrust)
	}, [navigate, clearCraftSiteSelection, craftSiteHandleParameter, shouldRedirect])

	// If the Craft site handle in the URL doesn't exist then send the user to the Select Trust page
	useEffect(() => {
		if (craftSiteHandleParameter === null) return
		if (!isFetchedSiteReady || fetchedSite !== null) return

		clearCraftSiteSelection()
		if (shouldRedirect) navigate(Routes.SelectTrust)
	}, [navigate, clearCraftSiteSelection, craftSiteHandleParameter, isFetchedSiteReady, fetchedSite, shouldRedirect])

	// If the Craft site handle in the URL is different to the Redux store then update it
	useEffect(() => {
		if (craftSiteHandleParameter === null) return
		if (!isFetchedSiteReady || fetchedSite === null) return
		if (siteInStore !== null && siteInStore.id === fetchedSite.id) return

		updateCraftSiteSelection(fetchedSite)
	}, [updateCraftSiteSelection, craftSiteHandleParameter, isFetchedSiteReady, fetchedSite, siteInStore])

	return siteInStore
}
