import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { NEVER_CRAFT_ID } from "~/constants"

import { useCraftEntries } from "~/hooks/craft/use-craft-entries"
import { useCraftEntry } from "~/hooks/craft/use-craft-entry"
import type { CraftHospitalEntry, CraftPartialHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftParentsEntry, CraftPartialParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftPartialProfessionalsEntry, CraftProfessionalsEntry } from "~/types/api/craft/sections/professionals"

/**
 * Fetches the symptoms Craft entry for the given Craft site and Craft section.
 * @param params The parameters.
 * @param params.siteId The identifier of the Craft site to fetch the home Craft entry within.
 * @param params.sectionId The identifier of the Craft section to fetch the home Craft entry within.
 * @param params.skip Whether to skip fetching the Craft entry.
 * @returns The home Craft entry and its state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftSymptomsEntry = <
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
	EntryType extends CraftHospitalEntry | CraftParentsEntry | CraftProfessionalsEntry
>({
	siteId,
	sectionId,

	skip = false
}: {
	siteId: number | null
	sectionId: number | null

	skip?: boolean
}): {
	entry: EntryType | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const {
		entries,
		isReady: isEntriesReady,
		isSuccess: isEntriesSuccess,
		isError: isEntriesError,
		error: entriesError
	} = useCraftEntries<CraftPartialHospitalEntry | CraftPartialParentsEntry | CraftPartialProfessionalsEntry>({
		siteId,
		sectionId,
		skip
	})

	const partialEntry = useMemo<
		CraftPartialHospitalEntry | CraftPartialParentsEntry | CraftPartialProfessionalsEntry | null
	>(
		() => entries?.find(({ slug, title }) => slug === "symptoms" || title.toLowerCase() === "symptoms") ?? null,
		[entries]
	)

	const {
		entry,
		isReady: isEntryReady,
		isSuccess: isEntrySuccess,
		isError: isEntryError,
		error: entryError
	} = useCraftEntry<CraftHospitalEntry | CraftParentsEntry | CraftProfessionalsEntry>({
		siteId,
		sectionId,
		entryId: partialEntry?.id ?? NEVER_CRAFT_ID,
		skip: skip || partialEntry === null
	})

	return {
		entry: entry as EntryType | null,

		isReady: isEntriesReady && isEntryReady,
		isSuccess: isEntriesSuccess && isEntrySuccess,
		isError: isEntriesError || isEntryError,

		error: entriesError ?? entryError
	}
}
