import { type CraftContentBlockBase, CraftContentBlockType } from "~/types/api/craft/fields/content-blocks"
import type { CraftAsset } from "~/types/api/craft/models/asset"

/**
 * Represents a Craft icons content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftIconsContentBlock extends CraftContentBlockBase {
	type: CraftContentBlockType.Icons

	/**
	 * The icons within this icons block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	icons: Array<{
		/**
		 * The heading for this icon.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		label: string

		/**
		 * The asset itself.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		asset: CraftAsset | null
	}>
}

/**
 * Ensures that a value is a Craft icons content block.
 * @param value The value to check.
 * @returns Whether the value is a Craft icons content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftIconsContentBlock = (value: unknown): value is CraftIconsContentBlock =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftContentBlockType.Icons.valueOf() &&
	"icons" in value &&
	Array.isArray(value.icons)
