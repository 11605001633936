import { useCallback, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import Button from "~/components/standard/button"
import type { CraftChoiceContentBlock } from "~/types/api/craft/fields/content-blocks/choice"
import { isCraftEntryLink, type CraftEntryLinkTarget } from "~/types/api/craft/link"
import type { CraftEntryState } from "~/types/state"

const ChoiceButton = ({
	label,
	target,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "onClick"> & {
	label: string
	target?: CraftEntryLinkTarget
}): JSX.Element => {
	const navigate = useNavigate()

	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (target === undefined) {
			console.warn(`No entry for choice button to navigate to!`)
			return
		}

		navigate(target.url, {
			state: {
				target
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, target])

	return (
		<Button
			{...props}
			label={label}
			onClick={onClick}
			className={`text-logo min-h-12 flex-1 items-center justify-center rounded-2xl p-2 font-bold duration-hover ${props.disabled === true ? "!hover:bg-control-hover !active:bg-control-active pointer-events-none cursor-not-allowed !bg-control-disabled-background !text-control-disabled-text" : ""} ${props.className ?? ""}`.trimEnd()}
		/>
	)
}

/**
 * Two buttons for making a choice.
 * Not guaranteed to always say 'Yes' and 'No'.
 * @returns A React component.
 * @example <ChoiceContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ChoiceContentBlock = ({
	block,
	...props
}: ComponentPropsWithRef<"div"> & { block: CraftChoiceContentBlock }): JSX.Element => {
	if (!isCraftEntryLink(block.positive)) throw new Error("Positive outcome does not target an entry!")
	if (!isCraftEntryLink(block.negative)) throw new Error("Negative outcome does not target an entry!")

	return (
		<div {...props} className={`flex flex-row justify-between gap-x-4 ${props.className ?? ""}`.trimEnd()}>
			<ChoiceButton
				label="Yes" // {block.yesLink.text}
				target={block.positive.target ?? undefined}
				disabled={block.positive.target === null}
				className="bg-logo-purple text-white hover:bg-purple-alt active:bg-purple-alt/95"
			/>
			<ChoiceButton
				label="No" // {block.noLink.text}
				target={block.negative.target ?? undefined}
				disabled={block.negative.target === null}
				className="bg-purple-alt/15 text-logo-purple hover:bg-purple-alt/25 active:bg-purple-alt/35"
			/>
		</div>
	)
}

export default ChoiceContentBlock
