import { useMemo } from "react"
import { setCraftEntry, setFurthestDropDownIndex, type Breadcrumbs } from "~/state/slices/breadcrumbs"
import { useReduxDispatch, useReduxSelector } from "~/state/store"
import type { CraftPartialAboutEntry } from "~/types/api/craft/sections/about"
import { isCraftHomeEntry } from "~/types/api/craft/sections/home"
import type { CraftPartialHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftPartialParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftPartialProfessionalsEntry } from "~/types/api/craft/sections/professionals"
import type { EmptyCallback } from "~/types/components/empty"

export type BreadcrumbEntryTypes =
	| CraftPartialAboutEntry
	| CraftPartialHospitalEntry
	| CraftPartialParentsEntry
	| CraftPartialProfessionalsEntry

/**
 * Updates the breadcrumbs.
 * @returns The callbacks for updating the Redux store for breadcrumbs.
 * @example const { updateFurthestDropDownIndex, updateCurrentEntryAncestors } = useBreadcrumbsDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useBreadcrumbsDispatch = (): {
	updateFurthestDropDownIndex: (index: Breadcrumbs["furthestDropDownIndex"]) => void
	resetFurthestDropDownIndex: EmptyCallback

	updateCraftEntry: (ancestors: Breadcrumbs["craftEntry"]) => void
	clearCraftEntry: EmptyCallback
} => {
	const dispatch = useReduxDispatch()

	return {
		updateFurthestDropDownIndex: (index: Breadcrumbs["furthestDropDownIndex"]) =>
			dispatch(setFurthestDropDownIndex(index)),
		resetFurthestDropDownIndex: () => dispatch(setFurthestDropDownIndex(0)),

		updateCraftEntry: (ancestors: Breadcrumbs["craftEntry"]) => dispatch(setCraftEntry(ancestors)),
		clearCraftEntry: () => dispatch(setCraftEntry(null))
	}
}

/**
 * Retrieves the current/active Craft entry from the breadcrumbs Redux store.
 * @returns The current/active Craft entry.
 * @example const craftEntry = useActiveCraftEntrySelection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useActiveCraftEntrySelection = (): Breadcrumbs["craftEntry"] =>
	useReduxSelector(({ breadcrumbs }) => breadcrumbs.craftEntry)

/**
 * Retrieves the furthest drop-down index of the breadcrumbs from the Redux store.
 * This is used to determine which breadcrumbs to display/where the cut off point is.
 * @returns The furthest drop-down index.
 * @example const furthestDropDownIndex = useFurthestDropDownIndexSelection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.1.0
 */
export const useFurthestDropDownIndexSelection = (): Breadcrumbs["furthestDropDownIndex"] =>
	useReduxSelector(({ breadcrumbs }) => breadcrumbs.furthestDropDownIndex)

/**
 * Constructs the breadcrumbs for the current/active Craft entry.
 * @returns The breadcrumbs for the current/active Craft entry.
 * @example const breadcrumbs = useBreadcrumbs()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useBreadcrumbs = (): [BreadcrumbEntryTypes[] | null, boolean] => {
	const craftEntry = useActiveCraftEntrySelection()

	const breadcrumbs = useMemo<BreadcrumbEntryTypes[] | null>(() => {
		if (craftEntry === null) return null
		if (isCraftHomeEntry(craftEntry)) return null

		const ancestors = craftEntry.ancestors.toSorted(({ level: a }, { level: b }) => a - b)
		return [...ancestors, craftEntry]
	}, [craftEntry])

	const hasBreadcrumbs = useMemo<boolean>(() => breadcrumbs !== null && breadcrumbs.length > 1, [breadcrumbs])

	return [breadcrumbs, hasBreadcrumbs]
}
