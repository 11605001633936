import { isCraftEntry, type CraftEntry, type CraftPartialEntry } from "~/types/api/craft/models/entry"
import type { Modify } from "~/types/helpers"

/**
 * Represents a partial Craft entry type for entries in the home section.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftPartialHomeEntry = Modify<
	CraftPartialEntry,
	{
		fields: Pick<CraftPartialEntry["fields"], "icon">
	}
>

/**
 * Represents a Craft entry type for entries in the home section.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */

export type CraftHomeEntry = Modify<
	Omit<CraftEntry, "ancestors">,
	{
		fields: Pick<CraftEntry["fields"], "aboutButton" | "backgroundImage" | "icon" | "roleButtons">
	}
>

/**
 * Ensures the given entry is a Craft entry for the home section.
 * @param value The value to validate.
 * @returns Whether the value is a Craft entry for the home section.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftHomeEntry = (value: unknown): value is CraftHomeEntry =>
	isCraftEntry(value) &&
	"aboutButton" in value.fields &&
	"backgroundImage" in value.fields &&
	"icon" in value.fields &&
	"roleButtons" in value.fields
