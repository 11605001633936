import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { NEVER_CRAFT_ID } from "~/constants"
import { useCraftEntry } from "~/hooks/craft/use-craft-entry"
import { useCraftHomeEntry } from "~/hooks/craft/use-craft-home-entry"
import { useCraftSections } from "~/hooks/craft/use-craft-sections"
import { isCraftEntryLink } from "~/types/api/craft/link"
import type { CraftSection } from "~/types/api/craft/models/section"
import type { CraftAboutEntry } from "~/types/api/craft/sections/about"

/**
 * Fetches the about Craft entry for the given Craft site.
 * @param params The parameters.
 * @param params.siteId The identifier of the Craft site to fetch the about Craft entry within.
 * @param params.skip Whether to skip fetching the Craft entry.
 * @returns The about Craft entry and its state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftAboutEntry = ({
	siteId,

	skip = false
}: {
	siteId: number | null

	skip?: boolean
}): {
	entry: CraftAboutEntry | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const {
		entry: homeEntry,
		isReady: isHomeEntryReady,
		isSuccess: isHomeEntrySuccess,
		isError: isHomeEntryError,
		error: homeEntryError
	} = useCraftHomeEntry({
		siteId,
		skip
	})

	const entryId = useMemo<number | null>(() => {
		const link = homeEntry?.fields.aboutButton.link
		if (!isCraftEntryLink(link)) return null

		return link.target?.entry ?? null
	}, [homeEntry])

	const {
		sections,
		isReady: isSectionsReady,
		isSuccess: isSectionsSuccess,
		isError: isSectionsError,
		error: sectionsError
	} = useCraftSections({ siteId, skip })

	const section = useMemo<CraftSection | null>(
		() => sections?.find(({ handle }) => handle === "about") ?? null,
		[sections]
	)

	const {
		entry: aboutEntry,
		isReady: isAboutEntryReady,
		isSuccess: isAboutEntrySuccess,
		isError: isAboutEntryError,
		error: aboutEntryError
	} = useCraftEntry<CraftAboutEntry>({
		siteId,
		sectionId: section?.id ?? NEVER_CRAFT_ID,
		entryId: entryId ?? NEVER_CRAFT_ID,
		skip: skip || section === null || entryId === null
	})

	return {
		entry: aboutEntry,

		isReady: isHomeEntryReady && isSectionsReady && isAboutEntryReady,
		isSuccess: isHomeEntrySuccess && isSectionsSuccess && isAboutEntrySuccess,
		isError: isHomeEntryError || isSectionsError || isAboutEntryError,

		error: homeEntryError ?? sectionsError ?? aboutEntryError
	}
}
