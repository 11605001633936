import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { useCraftEntry } from "~/hooks/craft/use-craft-entry"
import { useCraftResolveEntry } from "~/hooks/craft/use-craft-resolve-entry"
import { useCraftSymptomsEntry } from "~/hooks/craft/use-craft-symptoms-entry"
import { useRouteParameters } from "~/hooks/router/use-route-parameters"
import { useBreadcrumbsDispatch } from "~/hooks/use-breadcrumbs"
import { useRouterState } from "~/hooks/use-router-state"
import type { CraftSection } from "~/types/api/craft/models/section"
import type { CraftSite } from "~/types/api/craft/models/site"
import type { CraftAboutEntry } from "~/types/api/craft/sections/about"
import type { CraftHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftProfessionalsEntry } from "~/types/api/craft/sections/professionals"
import type { CraftEntryState } from "~/types/state"

/**
 * Fetches the current Craft entry using the URL parameters or React Router state.
 * This will automatically update the Craft entry ancestors in the Redux store (for breadcrumbs).
 * This will automatically redirect to the Symptoms page if an invalid Craft entry is detected, or if the URL parameters are missing.
 * @param craftSite The Craft site to fetch the entry within. Obtain this via useRouteCraftSite(). Omit when dealing purely with React Router state.
 * @param craftSection The Craft section to fetch the entry within. Obtain this via useRouteCraftSection(). Omit when dealing purely with React Router state.
 * @returns The current Craft entry, or null if the relevant URL parameters are not present, or the Craft entry does not exist.
 * @example const craftEntry = useRouteCraftEntry()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useRouteCraftEntry = <
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
	EntryType extends CraftAboutEntry | CraftHospitalEntry | CraftParentsEntry | CraftProfessionalsEntry
>(
	craftSite: CraftSite | null,
	craftSection: CraftSection | null
): {
	entry: EntryType | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const navigate = useNavigate()
	const { craftEntrySlugParameter } = useRouteParameters()

	const { updateCraftEntry, clearCraftEntry } = useBreadcrumbsDispatch()

	// Read the Craft entry target from the React Router state
	const routerState = useRouterState<CraftEntryState>()
	const routerStateTarget = useMemo(() => routerState?.target ?? null, [routerState])

	// Fetch the Craft entry that matches the URL parameter, only if we have no React Router state
	const {
		entry: resolvedEntry,
		isReady: isResolvedEntryReady,
		isSuccess: isResolvedEntrySuccess,
		isError: isResolvedEntryError,
		error: resolvedEntryError
	} = useCraftResolveEntry<EntryType>({
		siteHandle: craftSite?.handle ?? null,
		sectionHandle: craftSection?.handle ?? null,
		entrySlug: craftEntrySlugParameter,
		skip: routerStateTarget !== null
	})

	// Fetch the Craft entry that matches the React Router state
	const {
		entry: routerStateEntry,
		isReady: isRouterStateEntryReady,
		isSuccess: isRouterStateEntrySuccess,
		isError: isRouterStateEntryError,
		error: routerStateEntryError
	} = useCraftEntry<EntryType>({
		siteId: routerStateTarget?.site ?? null,
		sectionId: routerStateTarget?.section ?? null,
		entryId: routerStateTarget?.entry ?? null
	})

	// Fetch the Symptoms entry
	const { entry: symptomsEntry } = useCraftSymptomsEntry({
		siteId: craftSite?.id ?? null,
		sectionId: craftSection?.id ?? null,
		skip: craftSection?.handle === "about" || craftSection?.handle === "home" // craftEntrySlugParameter !== null
	})

	// If there's no Craft entry slug in the URL then send the user to the page from React Router state, or fallback to the Symptoms page
	useEffect(() => {
		if (craftEntrySlugParameter !== null) return

		clearCraftEntry()
		if (routerStateEntry !== null) {
			if (routerStateTarget === null) return

			navigate(routerStateEntry.url, {
				state: {
					target: routerStateTarget
				} satisfies CraftEntryState as CraftEntryState
			})
		} else {
			if (craftSite === null || symptomsEntry === null) return

			navigate(symptomsEntry.url, {
				state: {
					target: {
						site: craftSite.id,
						section: symptomsEntry.section,
						entry: symptomsEntry.id,
						url: symptomsEntry.url
					}
				} satisfies CraftEntryState as CraftEntryState
			})
		}
	}, [
		navigate,
		clearCraftEntry,
		routerStateEntry,
		routerStateTarget,
		craftSite,
		craftEntrySlugParameter,
		symptomsEntry
	])

	// If the Craft section handle in the URL doesn't exist then send the user to the React Router state, or fallback to the Symptoms page
	useEffect(() => {
		if (craftEntrySlugParameter === null) return
		if (!isResolvedEntryReady || resolvedEntry !== null) return

		clearCraftEntry()
		if (routerStateEntry !== null) {
			if (routerStateTarget === null) return

			navigate(routerStateEntry.url, {
				state: {
					target: routerStateTarget
				} satisfies CraftEntryState as CraftEntryState
			})
		} else {
			if (craftSite === null || symptomsEntry === null) return

			navigate(symptomsEntry.url, {
				state: {
					target: {
						site: craftSite.id,
						section: symptomsEntry.section,
						entry: symptomsEntry.id,
						url: symptomsEntry.url
					}
				} satisfies CraftEntryState as CraftEntryState
			})
		}
	}, [
		navigate,
		clearCraftEntry,
		craftEntrySlugParameter,
		isResolvedEntryReady,
		resolvedEntry,
		routerStateEntry,
		routerStateTarget,
		craftSite,
		symptomsEntry
	])

	// Update the current entry ancestors for breadcrumbs
	useEffect(() => {
		if (resolvedEntry === null) return
		updateCraftEntry(resolvedEntry)
	}, [updateCraftEntry, resolvedEntry])
	useEffect(() => {
		if (routerStateEntry === null) return
		updateCraftEntry(routerStateEntry)
	}, [updateCraftEntry, routerStateEntry])

	return {
		entry: routerStateEntry ?? resolvedEntry,

		isReady: isRouterStateEntryReady || isResolvedEntryReady,
		isSuccess: isRouterStateEntrySuccess || isResolvedEntrySuccess,
		isError: isRouterStateEntryError || isResolvedEntryError,

		error: routerStateEntryError ?? resolvedEntryError
	}
}
