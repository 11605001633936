import { useMemo } from "react"
import { useParams } from "react-router-dom"

import { orNull } from "~/helpers/primitives"

/**
 * Extracts the URL parameters for the current route.
 * @returns The URL parameters.
 * @example const { craftSiteParameter, craftSectionParameter, craftEntrySlugParameter } = useParameters()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useRouteParameters = (): {
	craftSiteHandleParameter: string | null
	craftSectionHandleParameter: string | null
	craftEntrySlugParameter: string | null
} => {
	const parameters = useParams()

	return {
		craftSiteHandleParameter: useMemo<string | null>(() => orNull(parameters["site"]), [parameters]),
		craftSectionHandleParameter: useMemo<string | null>(() => orNull(parameters["role"]), [parameters]),
		craftEntrySlugParameter: useMemo<string | null>(() => orNull(parameters["slug"]), [parameters])
	}
}
