/**
 * The types of Craft link fields.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftLinkType {
	Entry = 1,
	Asset = 2,
	Telephone = 3,
	URL = 4
}

/**
 * The target on a Craft entry link field.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftEntryLinkTarget {
	/**
	 * The site ID of the entry this link points to.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	site: number

	/**
	 * The section ID of the entry this link points to.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	section: number

	/**
	 * The entry ID this link points to.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	entry: number

	/**
	 * The relative URL to this entry.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	url: string
}

/**
 * Represents a Craft link field.
 * This is the base type for all Craft link fields, and should not be used directly.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftLinkBase {
	/**
	 * The type of link this is.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	type: CraftLinkType

	/**
	 * The custom text for this link.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	text: string | null

	/**
	 * The URL for this link points to.
	 * This can be a relative or absolute URL, and can point to a web page, downloadable asset, etc.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	url: string | null
}

/**
 * Represents a Craft link field for assets.
 * This is identical to a Craft link field for URLs, but is a separate type for clarity.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftAssetLink = CraftLinkBase & {
	type: CraftLinkType.Asset
}

/**
 * Represents a Craft link field for URLs.
 * This is identical to a Craft link field for assets, but is a separate type for clarity.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftURLLink = CraftLinkBase & {
	type: CraftLinkType.URL
}

/**
 * Represents a Craft link field for entries.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftEntryLink = Omit<CraftLinkBase, "url"> & {
	type: CraftLinkType.Entry

	/**
	 * The entry ID this link points to.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	target: CraftEntryLinkTarget | null
}

/**
 * Represents a Craft link field for telephone numbers.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftTelephoneLink = Omit<CraftLinkBase, "url"> & {
	type: CraftLinkType.Telephone

	/**
	 * The phone number this link points to.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	number: string | null
}

/**
 * Any Craft link field.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftLink = CraftAssetLink | CraftEntryLink | CraftTelephoneLink | CraftURLLink

/**
 * Ensures that a value is a Craft link field for assets.
 * @param value The value to check.
 * @returns Whether the value is a Craft link field for assets.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftAssetLink = (value: unknown): value is CraftAssetLink =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftLinkType.Asset.valueOf() &&
	"text" in value &&
	"url" in value

/**
 * Ensures that a value is a Craft link field for URLs.
 * @param value The value to check.
 * @returns Whether the value is a Craft link field for URLs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftURLLink = (value: unknown): value is CraftURLLink =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftLinkType.URL.valueOf() &&
	"text" in value &&
	"url" in value

/**
 * Ensures that a value is a Craft link field for entries.
 * @param value The value to check.
 * @returns Whether the value is a Craft link field for entries.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftEntryLink = (value: unknown): value is CraftEntryLink =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftLinkType.Entry.valueOf() &&
	"text" in value &&
	"target" in value

/**
 * Ensures that a value is a Craft link field for telephone numbers.
 * @param value The value to check.
 * @returns Whether the value is a Craft link field for telephone numbers.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftTelephoneLink = (value: unknown): value is CraftTelephoneLink =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftLinkType.Telephone.valueOf() &&
	"text" in value &&
	"number" in value
