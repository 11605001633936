import type { CraftElement } from "~/types/api/craft/element"

/**
 * Represents a Craft section.
 * This is identical to a Craft site, but is a separate type for clarity.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftSection extends CraftElement {
	/**
	 * The friendly name of this section.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	name: "About" | "Home" | "Hospital" | "Parents" | "Professionals"

	/**
	 * The developer handle for this section.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	handle: "about" | "home" | "hospital" | "parents" | "professionals"
}

/**
 * Ensures that a value is a Craft section.
 * @param value The value to check.
 * @returns Whether the value is a Craft section.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftSection = (value: unknown): value is CraftSection =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"id" in value &&
	"name" in value &&
	"handle" in value
