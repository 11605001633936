import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useSearchEntriesQuery } from "~/api/client"
import { NEVER_CRAFT_ID } from "~/constants"
import type { CraftPartialAboutEntry } from "~/types/api/craft/sections/about"
import type { CraftPartialHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftPartialParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftPartialProfessionalsEntry } from "~/types/api/craft/sections/professionals"

export type SearchableCraftEntryTypes =
	| CraftPartialAboutEntry
	| CraftPartialHospitalEntry
	| CraftPartialParentsEntry
	| CraftPartialProfessionalsEntry

/**
 * Fetches all Craft entries for all Craft sections (except home) within a Craft site.
 * These will be partial Craft entries! Use the useCraftEntry() React hook to fetch full Craft entries.
 * @param params The parameters.
 * @param params.siteId The identifier of the Craft site to fetch entries within.
 * @param params.skip Whether to skip fetching Craft entries.
 * @returns The partial Craft entries & API request status.
 * @example const { entries } = useCraftEntries()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftSearch = <EntryType extends SearchableCraftEntryTypes = SearchableCraftEntryTypes>({
	siteId,

	skip = false
}: {
	siteId: number | null

	skip?: boolean
}): {
	entries: EntryType[] | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { data, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useSearchEntriesQuery(
		{
			siteId: siteId ?? NEVER_CRAFT_ID
		},
		{
			skip: skip || siteId === null
		}
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	return {
		entries: (data as EntryType[] | undefined) ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
