import type { CraftElement } from "~/types/api/craft/element"
import type { CraftAboutButton } from "~/types/api/craft/fields/about-button"
import type { CraftCitation } from "~/types/api/craft/fields/citations"
import type { CraftContentBlock } from "~/types/api/craft/fields/content-blocks"
import type { CraftRoleButton } from "~/types/api/craft/fields/role-buttons"
import type { CraftSeverity } from "~/types/api/craft/fields/severity"
import type { CraftAsset } from "~/types/api/craft/models/asset"
import type { Modify } from "~/types/helpers"

/**
 * Represents a partial Craft entry.
 * Do not use this directly, instead use a specific section/entry type!
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftPartialEntry extends CraftElement {
	/**
	 * The friendly title of this entry.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	title: string

	/**
	 * The identifier of the Craft section this entry is within.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	section: number

	/**
	 * How deep this entry is within its structure.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	level: number

	/**
	 * The URL slug of this entry.
	 * This will not include the site's & section's handle, use the `url` field for that.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	slug: string

	/**
	 * The relative URL of this entry.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	url: string

	/**
	 * The fields this entry has.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	fields: {
		severity: CraftSeverity | null
		icon: CraftAsset | null
		backgroundImage: CraftAsset | null
		citations: CraftCitation[]
		contentBlocks: CraftContentBlock[]
		roleButtons: CraftRoleButton[]
		aboutButton: CraftAboutButton
	}
}

/**
 * Represents a Craft entry.
 * Do not use this directly, instead use a specific section/entry type!
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftEntry extends CraftPartialEntry {
	/**
	 * When this entry was last updated.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	updatedAt: string

	/**
	 * This entry's parents.
	 * This is ordered from the closest (immediate) parent to the furthest (root) parent.
	 * This will never contain a home entry type.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	ancestors: Array<
		Modify<
			CraftPartialEntry,
			{
				fields: Pick<CraftPartialEntry["fields"], "icon" | "severity">
			}
		>
	>
}

/**
 * Ensures that a value is a Craft entry.
 * @param value The value to check.
 * @returns Whether the value is a Craft entry.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftEntry = (value: unknown): value is CraftEntry =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"id" in value &&
	"title" in value &&
	"slug" in value &&
	"url" in value &&
	"fields" in value
