import { useCallback } from "react"

import { updateFirebaseAnalyticsDefaultEventParameters } from "~/helpers/analytics"
import { LocalStorageKeys, updateSection, updateSite } from "~/state/slices/selections"
import { useReduxDispatch, useReduxSelector } from "~/state/store"
import type { CraftSection } from "~/types/api/craft/models/section"
import type { CraftSite } from "~/types/api/craft/models/site"
import type { EmptyCallback } from "~/types/components/empty"

type UpdateCraftSiteIdentifierCallback = (craftSite: CraftSite) => void
type UpdateCraftSectionIdentifierCallback = (craftSection: CraftSection) => void

/**
 * A React hook to update Craft selections in the Redux store.
 * @returns Functions to update the Craft site & section selections.
 * @example const { updateCraftSiteSelection, updateCraftSectionSelection } = useSelectionsDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftSelectionsDispatch = (): {
	updateCraftSiteSelection: UpdateCraftSiteIdentifierCallback
	updateCraftSectionSelection: UpdateCraftSectionIdentifierCallback
	clearCraftSiteSelection: EmptyCallback
	clearCraftSectionSelection: EmptyCallback
} => {
	const dispatch = useReduxDispatch()

	return {
		updateCraftSiteSelection: useCallback<UpdateCraftSiteIdentifierCallback>(
			craftSite => {
				window.localStorage.removeItem(LocalStorageKeys.CraftSiteSelectionJSON.valueOf())

				updateFirebaseAnalyticsDefaultEventParameters({
					craftSiteId: craftSite.id,
					craftSiteHandle: craftSite.handle,
					craftSiteName: craftSite.name
				})

				dispatch(updateSite(craftSite))
			},
			[dispatch]
		),

		updateCraftSectionSelection: useCallback<UpdateCraftSectionIdentifierCallback>(
			craftSection => {
				window.localStorage.removeItem(LocalStorageKeys.CraftSectionSelectionJSON.valueOf())

				// Don't categorise requests with the home/about sections as they aren't actual roles
				if (craftSection.handle !== "home" && craftSection.handle !== "about")
					updateFirebaseAnalyticsDefaultEventParameters({
						craftSectionId: craftSection.id,
						craftSectionHandle: craftSection.handle,
						craftSectionName: craftSection.name
					})
				else
					updateFirebaseAnalyticsDefaultEventParameters({
						craftSectionId: null,
						craftSectionHandle: null,
						craftSectionName: null
					})

				dispatch(updateSection(craftSection))
			},
			[dispatch]
		),

		clearCraftSiteSelection: useCallback(() => {
			window.localStorage.removeItem(LocalStorageKeys.CraftSiteSelectionJSON.valueOf())

			updateFirebaseAnalyticsDefaultEventParameters({
				craftSiteId: null,
				craftSiteHandle: null,
				craftSiteName: null
			})

			dispatch(updateSite(null))
		}, [dispatch]),

		clearCraftSectionSelection: useCallback(() => {
			window.localStorage.removeItem(LocalStorageKeys.CraftSectionSelectionJSON.valueOf())

			updateFirebaseAnalyticsDefaultEventParameters({
				craftSectionId: null,
				craftSectionHandle: null,
				craftSectionName: null
			})

			dispatch(updateSection(null))
		}, [dispatch])
	}
}

/**
 * A React hook to retrieve the Craft site selection from the Redux store.
 * @returns The current Craft site selection, or null if not selected yet.
 * @example const craftSite = useCraftSiteSelection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftSiteSelection = (): CraftSite | null => useReduxSelector(store => store.selections.craftSite)

/**
 * A React hook to retrieve the Craft section selection from the Redux store.
 * @returns The current Craft section selection, or null if not selected yet
 * @example const craftSection = useCraftSectionSelection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftSectionSelection = (): CraftSection | null =>
	useReduxSelector(store => store.selections.craftSection)
