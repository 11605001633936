import { useCallback, useEffect, useMemo, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import Breadcrumbs from "~/components/header/breadcrumbs"
import Search from "~/components/header/search"
import { useRouteCraftSite } from "~/hooks/router/use-route-craft-site"
import { useNavigateBack } from "~/hooks/use-navigate-back"
import { Routes } from "~/router/routes"
import { setBreadcrumbsVisibility, setSearchVisibility } from "~/state/slices/layout"
import { useReduxDispatch, useReduxSelector } from "~/state/store"

import ChevronIcon from "~/assets/icons/chevron.svg?react"
import MenuIcon from "~/assets/icons/menu.svg?react"
import HANDiLogo from "~/assets/logos/handi.svg?react"

/**
 * The standard header for the application.
 * This includes the logo, back & menu button, and a search bar.
 * @returns The React component.
 * @example <Header />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Header = ({
	disableBackButton = false,

	showBackButton = true,
	showSettingsButton = true,

	showSearch = false,
	showBreadcrumbs = false,
	showGrab = false,

	shouldRedirect = true,

	...props
}: ComponentPropsWithRef<"header"> & {
	disableBackButton?: boolean

	showBackButton?: boolean
	showSettingsButton?: boolean

	showSearch?: boolean
	showGrab?: boolean
	showBreadcrumbs?: boolean

	shouldRedirect?: boolean
}): JSX.Element => {
	const navigate = useNavigate()

	const { onClick: onBackClick, hasHistory } = useNavigateBack<SVGSVGElement>()

	// Navigates to settings page when the hamburger menu button is clicked...
	const onSettingsClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		navigate(Routes.Settings)
	}, [navigate])

	// Navigates home when the logo is clicked...
	const craftSite = useRouteCraftSite(shouldRedirect)
	const onLogoClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (craftSite === null) {
			console.warn("No site selection yet?!")
			return
		}

		navigate(craftSite.url)
	}, [navigate, craftSite])

	// Always ensure state is reset when components are removed...
	const dispatch = useReduxDispatch()
	useEffect(() => {
		if (!showSearch) dispatch(setSearchVisibility(false))
		if (!showBreadcrumbs) dispatch(setBreadcrumbsVisibility(false))
	}, [dispatch, showSearch, showBreadcrumbs])

	// Dynamically update our height based on what components we're rendering
	// NOTE: This has to be set manually rather than relying on the header resizing to accommodate its children for the transition/animation to work!
	const isBreadcrumbsVisible = useReduxSelector(({ layout }) => layout.isBreadcrumbsVisible)
	const isSearchVisible = useReduxSelector(({ layout }) => layout.isSearchVisible)
	const heightStyle = useMemo(
		() =>
			`calc(4.5rem + ${isBreadcrumbsVisible ? "4rem" : "0rem"} + ${isSearchVisible ? "4.25rem" : "0rem"} + ${showGrab ? "1rem" : "0rem"})`,
		[isBreadcrumbsVisible, isSearchVisible, showGrab]
	)

	return (
		<header
			{...props}
			className={`z-10 mx-4 flex flex-col gap-y-2 overflow-hidden rounded-b-2xl bg-white p-2 shadow-lg transition-all duration-ui ${props.className ?? ""}`.trimEnd()}
			style={{
				height: heightStyle,
				minHeight: heightStyle,
				maxHeight: heightStyle
			}}>
			<div className="flex flex-row items-center justify-between">
				{/* Back */}
				<ChevronIcon
					className={`aspect-square rounded-xl p-3 ps-2 duration-hover ${disableBackButton ? "fill-control-disabled pointer-events-none cursor-not-allowed" : "fill-logo-purple hover:cursor-pointer hover:bg-control-hover active:bg-control-active"} ${!showBackButton || !hasHistory ? "pointer-events-none opacity-0" : ""}`.trimEnd()}
					width={48}
					height={48}
					onClick={onBackClick}
					aria-disabled={disableBackButton || !showBackButton || !hasHistory}
				/>

				{/* Logo */}
				<HANDiLogo
					className="aspect-square hover:cursor-pointer"
					width={56}
					height={56}
					onClick={onLogoClick}
				/>

				{/* Settings */}
				<MenuIcon
					className={`aspect-square rounded-xl fill-logo-purple p-3 duration-hover hover:cursor-pointer hover:bg-control-hover active:bg-control-active ${!showSettingsButton ? "pointer-events-none opacity-0" : ""}`.trimEnd()}
					width={48}
					height={48}
					onClick={onSettingsClick}
				/>
			</div>

			{/* Breadcrumbs */}
			{showBreadcrumbs && <Breadcrumbs />}

			{/* Search */}
			{showSearch && <Search />}

			{/* Grab bar */}
			{showGrab && (
				<div className="flex flex-row justify-center">
					<hr className="h-1 w-1/4 cursor-move rounded-full bg-gray-200" />
				</div>
			)}
		</header>
	)
}

export default Header
