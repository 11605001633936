import {
	createApi,
	fetchBaseQuery,
	retry,
	type BaseQueryFn,
	type FetchArgs,
	type FetchBaseQueryError,
	type FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react"
import { captureEvent } from "@sentry/react"

import { REACT_VERSION } from "~/constants"
import { orNull } from "~/helpers/primitives"
import type { CraftLivePreview } from "~/types/api/craft/live-preview"
import type { CraftEntry, CraftPartialEntry } from "~/types/api/craft/models/entry"
import type { CraftSection } from "~/types/api/craft/models/section"
import type { CraftSite } from "~/types/api/craft/models/site"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const API_BASE_URL = orNull(import.meta.env.VITE_API_BASE_URL)
if (API_BASE_URL === null) throw new Error("The API base URL is missing!")
const API_KEY = orNull(import.meta.env.VITE_API_KEY)
if (API_KEY === null) throw new Error("The API key is missing!")

const baseQuery = fetchBaseQuery({
	baseUrl: API_BASE_URL,
	timeout: 5000, // 5 seconds
	method: "GET",
	prepareHeaders: (headers): void => {
		// Content
		headers.set("Accept", "application/json")

		// Authentication
		headers.set("Authorization", `Token ${API_KEY}`)

		// Identification
		headers.set(
			"User-Agent",
			`HANDi Paediatrics/${VITE_BITBUCKET_TAG ?? "0.0.0"} (technical@yello.studio; https://yello.studio)`
		)
		headers.set("From", "technical@yello.studio")

		// Technologies
		headers.set("X-Powered-By", `React/${REACT_VERSION ?? "19"} (https://react.dev)`)
		headers.set("X-Requested-With", "Redux Toolkit (https://redux-toolkit.js.org)")
	}
})

const retryingBaseQuery = retry(
	async (args: FetchArgs | string, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions)
		const status = result.error?.status

		// Never retry for parsing errors
		if (status === "PARSING_ERROR") {
			console.warn(`Query '${api.endpoint}' failed due to malformed response!`)
			retry.fail(result.error)
		}

		// Retry for network issues
		if (status === "TIMEOUT_ERROR") {
			console.warn(`Query '${api.endpoint}' timed out, retrying...`)
			return result
		}
		if (status === "FETCH_ERROR") {
			console.warn(`Query '${api.endpoint}' failed due to a network error, retrying...`)
			return result
		}

		// Retry for server-side errors (e.g., 500, 502, 504)
		if (typeof status === "number" && status >= 500 && status <= 599) {
			console.warn(`Query '${api.endpoint}' returned HTTP status code ${status.toString()}, retrying...`)
			return result
		}

		// Never retry for client-side errors (e.g., 401, 403, 409)
		if (typeof status === "number" && status >= 400 && status <= 499) {
			console.warn(`Query '${api.endpoint}' returned HTTP status code ${status.toString()}!`)
			retry.fail(result.error)
		}

		// Generic catch-all, as status is not set on success
		if (status !== undefined) console.warn(`Query '${api.endpoint}' failed due to '${status.toString()}'`)
		return result
	},
	{
		maxRetries: 3
	}
)

const sentryErrorCaptureBaseQuery: BaseQueryFn<
	FetchArgs,
	unknown,
	FetchBaseQueryError,
	Record<never, never>,
	FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
	const result = await retryingBaseQuery(args, api, extraOptions)
	const status = result.error?.status

	if (status !== undefined) {
		const hasStatusCode = typeof status === "number"
		const errorMessages: Record<Exclude<NonNullable<typeof status>, number>, string> = {
			TIMEOUT_ERROR: `RTK query '${api.endpoint}' timed out!`,
			FETCH_ERROR: `RTK query '${api.endpoint}' failed due to a network error!`,
			PARSING_ERROR: `RTK query '${api.endpoint}' received a malformed response!`,
			CUSTOM_ERROR: `RTK query '${api.endpoint}' failed!`
		}

		captureEvent({
			level: "error",
			message: hasStatusCode
				? `RTK query '${api.endpoint}' received HTTP ${status.toString()}'`
				: errorMessages[status],
			extra: {
				requestMethod: args.method,
				requestPath: args.url,
				requestHeaders: args.headers,
				requestBody:
					args.body !== undefined && args.body !== null && args.body !== null
						? JSON.stringify(args.body)
						: null,
				fetchError: !hasStatusCode ? status : null,
				responseCode: hasStatusCode ? status : null,
				responseData: result.error?.data,
				responseBody: result.data,
				rtkQueryEndpoint: api.endpoint
			}
		})
	}

	return result
}

export const api = createApi({
	reducerPath: "api",
	baseQuery: sentryErrorCaptureBaseQuery,
	endpoints: builder => ({
		/**
		 * Fetches a list of Craft sites.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
		fetchSites: builder.query<CraftSite[], void>({
			query: (): FetchArgs => ({
				url: "/sites"
			})
		}),

		/**
		 * Fetches an individual Craft sites.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		fetchSite: builder.query<
			CraftSite,
			{
				siteId: number
			}
		>({
			query: ({ siteId }): FetchArgs => ({
				url: `/sites/${siteId.toString()}`
			})
		}),

		/**
		 * Fetches a list of Craft sections within a Craft site.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */

		fetchSections: builder.query<
			CraftSection[],
			{
				siteId: number
			}
		>({
			query: ({ siteId }): FetchArgs => ({
				url: `/sites/${siteId.toString()}/sections`
			})
		}),

		/**
		 * Fetches an individual Craft section within a Craft site.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		fetchSection: builder.query<
			CraftSection,
			{
				siteId: number
				sectionId: number
			}
		>({
			query: ({ siteId, sectionId }): FetchArgs => ({
				url: `/sites/${siteId.toString()}/sections/${sectionId.toString()}`
			})
		}),

		/**
		 * Fetches a list of Craft entries within a Craft section within a Craft site.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		fetchEntries: builder.query<
			CraftPartialEntry[],
			{
				siteId: number
				sectionId: number
			}
		>({
			query: ({ siteId, sectionId }): FetchArgs => ({
				url: `/sites/${siteId.toString()}/sections/${sectionId.toString()}/entries`
			})
		}),

		/**
		 * Fetches an individual Craft entry within a Craft section within a Craft site.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		fetchEntry: builder.query<
			CraftEntry,
			{
				siteId: number
				sectionId: number
				entryId: number

				livePreview?: CraftLivePreview
			}
		>({
			query: ({ siteId, sectionId, entryId, livePreview }): FetchArgs => {
				// Support live previews from Craft - https://craftcms.com/docs/4.x/entries.html#previewing-decoupled-front-ends
				const searchParameters = new URLSearchParams()
				if (livePreview?.identifier !== undefined)
					searchParameters.set("x-craft-live-preview", livePreview.identifier)

				const headers = new Headers()
				if (livePreview?.token !== undefined) headers.set("X-Craft-Token", livePreview.token)

				return {
					url: `/sites/${siteId.toString()}/sections/${sectionId.toString()}/entries/${entryId.toString()}${searchParameters.toString() !== "" ? `?${searchParameters.toString()}` : ""}`
				}
			}
		}),

		/**
		 * Fetches a list of Craft entries for all Craft sections (except home) within a Craft site.
		 * This is used for search functionality.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		searchEntries: builder.query<
			CraftPartialEntry[],
			{
				siteId: number
			}
		>({
			query: ({ siteId }): FetchArgs => ({
				timeout: 10000, // 10 seconds
				url: `/search/${siteId.toString()}`
			})
		}),

		/**
		 * Resolves a Craft site by its handle
		 * This endpoint will redirect to the site's individual endpoint.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		resolveSite: builder.query<
			CraftSite | { siteId: number },
			{
				siteHandle: string
			}
		>({
			query: ({ siteHandle }): FetchArgs => ({
				url: `/resolve/${siteHandle}`,
				redirect: "follow"
			})
		}),

		/**
		 * Resolves a Craft section by its site handle and section handle.
		 * This endpoint will redirect to the section's individual endpoint.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		resolveSection: builder.query<
			CraftSection | { siteId: number; sectionId: number },
			{
				siteHandle: string
				sectionHandle: string
			}
		>({
			query: ({ siteHandle, sectionHandle }): FetchArgs => ({
				url: `/resolve/${siteHandle}/${sectionHandle}`,
				redirect: "follow"
			})
		}),

		/**
		 * Resolves a Craft entry by its site handle, section handle, and entry slug.
		 * This endpoint will redirect to the entry's individual endpoint.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 4.5.0
		 */
		resolveEntry: builder.query<
			CraftEntry | { siteId: number; sectionId: number; entryId: number },
			{
				siteHandle: string
				sectionHandle: string
				entrySlug: string
			}
		>({
			query: ({ siteHandle, sectionHandle, entrySlug }): FetchArgs => ({
				url: `/resolve/${siteHandle}/${sectionHandle}/${entrySlug}`,
				redirect: "follow"
			})
		})
	})
})

/**
 * Fetches a list of Craft sites.
 * @returns The query result.
 * @example const { data: sites } = useFetchSitesQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useFetchSitesQuery: typeof api.endpoints.fetchSites.useQuery = api.endpoints.fetchSites.useQuery

/**
 * Lazily fetches a list of Craft sites.
 * @returns The method to trigger the query.
 * @example const [fetchSites] = useLazyFetchSitesQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyFetchSitesQuery: typeof api.endpoints.fetchSites.useLazyQuery =
	api.endpoints.fetchSites.useLazyQuery

/**
 * Fetches an individual Craft site.
 * @returns The query result.
 * @example const { data: site } = useFetchSiteQuery({ siteId: 5 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useFetchSiteQuery: typeof api.endpoints.fetchSite.useQuery = api.endpoints.fetchSite.useQuery

/**
 * Lazily fetches an individual Craft site.
 * @returns The method to trigger the query.
 * @example const [fetchSite] = useLazyFetchSiteQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyFetchSiteQuery: typeof api.endpoints.fetchSite.useLazyQuery = api.endpoints.fetchSite.useLazyQuery

/**
 * Fetches a list of Craft sections within a Craft site.
 * @returns The query result.
 * @example const { data: sections } = useFetchSectionsQuery({ siteId: 5 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useFetchSectionsQuery: typeof api.endpoints.fetchSections.useQuery = api.endpoints.fetchSections.useQuery

/**
 * Lazily fetches a list of Craft sections within a Craft site.
 * @returns The method to trigger the query.
 * @example const [fetchSections] = useLazyFetchSectionsQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyFetchSectionsQuery: typeof api.endpoints.fetchSections.useLazyQuery =
	api.endpoints.fetchSections.useLazyQuery

/**
 * Fetches an individual Craft section within a Craft site.
 * @returns The query result.
 * @example const { data: section } = useFetchSectionQuery({ siteId: 5, sectionId: 3 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useFetchSectionQuery: typeof api.endpoints.fetchSection.useQuery = api.endpoints.fetchSection.useQuery

/**
 * Lazily fetches an individual Craft section within a Craft site.
 * @returns The method to trigger the query.
 * @example const [fetchSection] = useLazyFetchSectionQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyFetchSectionQuery: typeof api.endpoints.fetchSection.useLazyQuery =
	api.endpoints.fetchSection.useLazyQuery

/**
 * Fetches a list of Craft entries within a Craft section within a Craft site.
 * @returns The query result.
 * @example const { data: entries } = useFetchEntriesQuery({ siteId: 5, sectionId: 3 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useFetchEntriesQuery: typeof api.endpoints.fetchEntries.useQuery = api.endpoints.fetchEntries.useQuery

/**
 * Lazily fetches a list of Craft entries within a Craft section within a Craft site.
 * @returns The method to trigger the query.
 * @example const [fetchEntries] = useLazyFetchEntriesQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyFetchEntriesQuery: typeof api.endpoints.fetchEntries.useLazyQuery =
	api.endpoints.fetchEntries.useLazyQuery

/**
 * Fetches an individual Craft entry within a Craft section within a Craft site.
 * @returns The query result.
 * @example const { data: entry } = useFetchEntryQuery({ siteId: 5, sectionId: 3, entryId: 9000 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useFetchEntryQuery: typeof api.endpoints.fetchEntry.useQuery = api.endpoints.fetchEntry.useQuery

/**
 * Lazily fetches an individual Craft entry within a Craft section within a Craft site.
 * @returns The method to trigger the query.
 * @example const [fetchEntry] = useLazyFetchEntryQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyFetchEntryQuery: typeof api.endpoints.fetchEntry.useLazyQuery =
	api.endpoints.fetchEntry.useLazyQuery

/**
 * Fetches a list of Craft entries for all Craft sections (except home) within a Craft site.
 * This is used for search functionality.
 * @returns The query result.
 * @example const { data: entries } = useSearchEntriesQuery({ siteId: 5 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useSearchEntriesQuery: typeof api.endpoints.searchEntries.useQuery = api.endpoints.searchEntries.useQuery

/**
 * Lazily fetches a list of Craft entries for all Craft sections (except home) within a Craft site.
 * This is used for search functionality.
 * @returns The method to trigger the query.
 * @example const [searchEntries] = useLazySearchEntriesQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazySearchEntriesQuery: typeof api.endpoints.searchEntries.useLazyQuery =
	api.endpoints.searchEntries.useLazyQuery

/**
 * Resolves a Craft site by its handle.
 * @returns The query result.
 * @example const { data: resolution } = useResolveSiteQuery({ siteHandle: 'bath' })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useResolveSiteQuery: typeof api.endpoints.resolveSite.useQuery = api.endpoints.resolveSite.useQuery

/**
 * Lazily resolves a Craft site by its handle.
 * @returns The method to trigger the query.
 * @example const [resolve] = useLazyResolveSiteQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyResolveSiteQuery: typeof api.endpoints.resolveSite.useLazyQuery =
	api.endpoints.resolveSite.useLazyQuery

/**
 * Resolves a Craft section by its site handle and section handle.
 * @returns The query result.
 * @example const { data: resolution } = useResolveSectionQuery({ siteHandle: 'bath', sectionHandle: 'parents' })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useResolveSectionQuery: typeof api.endpoints.resolveSection.useQuery =
	api.endpoints.resolveSection.useQuery

/**
 * Lazily resolves a Craft section by its site handle and section handle.
 * @returns The method to trigger the query.
 * @example const [resolve] = useLazyResolveSectionQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyResolveSectionQuery: typeof api.endpoints.resolveSection.useLazyQuery =
	api.endpoints.resolveSection.useLazyQuery

/**
 * Resolves a Craft entry by its site handle, section handle, and entry slug.
 * @returns The query result.
 * @example const { data: resolution } = useResolveQuery({ siteHandle: 'bath', sectionHandle: 'parents', entrySlug: 'parents' })
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useResolveEntryQuery: typeof api.endpoints.resolveEntry.useQuery = api.endpoints.resolveEntry.useQuery

/**
 * Lazily resolves a Craft entry by its site handle, section handle, and entry slug.
 * @returns The method to trigger the query.
 * @example const [resolve] = useLazyResolveQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useLazyResolveEntryQuery: typeof api.endpoints.resolveEntry.useLazyQuery =
	api.endpoints.resolveEntry.useLazyQuery
