import type { CraftChoiceContentBlock } from "~/types/api/craft/fields/content-blocks/choice"
import type { CraftIconsContentBlock } from "~/types/api/craft/fields/content-blocks/icons"
import type { CraftLinksContentBlock } from "~/types/api/craft/fields/content-blocks/links"
import type { CraftMapContentBlock } from "~/types/api/craft/fields/content-blocks/map"
import type { CraftMultipleImagesContentBlock } from "~/types/api/craft/fields/content-blocks/multiple-images"
import type { CraftSingleImageContentBlock } from "~/types/api/craft/fields/content-blocks/single-image"
import type { CraftTextContentBlock } from "~/types/api/craft/fields/content-blocks/text"

/**
 * The types of Craft link fields.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftContentBlockType {
	Text = 1,
	Icons = 2,
	Links = 3,
	Choice = 4,
	SingleImage = 5,
	MultipleImages = 6,
	Map = 7
}

/**
 * Represents a Craft content block.
 * This is a base interface for all content block types, do not use it directly!
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftContentBlockBase {
	/**
	 * The type of content block this is.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	type: CraftContentBlockType
}

/**
 * Any Craft content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftContentBlock =
	| CraftChoiceContentBlock
	| CraftIconsContentBlock
	| CraftLinksContentBlock
	| CraftMapContentBlock
	| CraftMultipleImagesContentBlock
	| CraftSingleImageContentBlock
	| CraftTextContentBlock
