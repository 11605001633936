import { isCraftEntry, type CraftEntry, type CraftPartialEntry } from "~/types/api/craft/models/entry"
import type { Modify } from "~/types/helpers"

/**
 * Represents a partial Craft entry type for entries in the hospital section.
 * This is identical to the partial Craft professionals & hospital section, but is a separate type for clarity.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftPartialHospitalEntry = Modify<
	CraftPartialEntry,
	{
		fields: Pick<CraftPartialEntry["fields"], "icon" | "severity">
	}
>

/**
 * Represents a Craft entry type for entries in the hospitals section.
 * This is identical to the Craft professionals & hospitals section, but is a separate type for clarity.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export type CraftHospitalEntry = Modify<
	CraftEntry,
	{
		fields: Pick<CraftEntry["fields"], "citations" | "contentBlocks" | "icon" | "severity">
	}
>

/**
 * Ensures the given entry is a Craft entry for the hospital section.
 * @param value The value to validate.
 * @returns Whether the value is a Craft entry for the hospital section.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftHospitalEntry = (value: unknown): value is CraftHospitalEntry =>
	isCraftEntry(value) &&
	"citations" in value.fields &&
	"contentBlocks" in value.fields &&
	"icon" in value.fields &&
	"severity" in value.fields
