import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { NEVER_CRAFT_ID } from "~/constants"

import { useCraftEntries } from "~/hooks/craft/use-craft-entries"
import { useCraftEntry } from "~/hooks/craft/use-craft-entry"
import { useCraftSections } from "~/hooks/craft/use-craft-sections"
import type { CraftSection } from "~/types/api/craft/models/section"
import type { CraftHomeEntry, CraftPartialHomeEntry } from "~/types/api/craft/sections/home"

/**
 * Fetches the home Craft entry for the given Craft site.
 * @param params The parameters.
 * @param params.siteId The identifier of the Craft site to fetch the home Craft entry within.
 * @param params.skip Whether to skip fetching the Craft entry.
 * @returns The home Craft entry and its state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftHomeEntry = ({
	siteId,

	skip = false
}: {
	siteId: number | null

	skip?: boolean
}): {
	entry: CraftHomeEntry | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const {
		sections,
		isReady: isSectionsReady,
		isSuccess: isSectionsSuccess,
		isError: isSectionsError,
		error: sectionsError
	} = useCraftSections({ siteId, skip })

	const section = useMemo<CraftSection | null>(
		() => sections?.find(({ handle }) => handle === "home") ?? null,
		[sections]
	)

	const {
		entries,
		isReady: isEntriesReady,
		isSuccess: isEntriesSuccess,
		isError: isEntriesError,
		error: entriesError
	} = useCraftEntries<CraftPartialHomeEntry>({
		siteId,
		sectionId: section?.id ?? NEVER_CRAFT_ID,
		skip: skip || section === null
	})

	// Fetching the home entries is a special case, as they'll only ever be one entry!
	const partialEntry = useMemo<CraftPartialHomeEntry | null>(() => entries?.[0] ?? null, [entries])

	const {
		entry,
		isReady: isEntryReady,
		isSuccess: isEntrySuccess,
		isError: isEntryError,
		error: entryError
	} = useCraftEntry<CraftHomeEntry>({
		siteId,
		sectionId: section?.id ?? NEVER_CRAFT_ID,
		entryId: partialEntry?.id ?? NEVER_CRAFT_ID,
		skip: skip || section === null || partialEntry === null
	})

	return {
		entry: entry,

		isReady: isSectionsReady && isEntriesReady && isEntryReady,
		isSuccess: isSectionsSuccess && isEntriesSuccess && isEntrySuccess,
		isError: isSectionsError || isEntriesError || isEntryError,

		error: sectionsError ?? entriesError ?? entryError
	}
}
