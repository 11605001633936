import type { CraftElement } from "~/types/api/craft/element"

/**
 * Represents a Craft site.
 * This is identical to a Craft section, but is a separate type for clarity.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftSite extends CraftElement {
	/**
	 * The friendly name of this site.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	name:
		| "Bath"
		| "Bristol"
		| "Cornwall"
		| "Derby"
		| "Herefordshire"
		| "North Devon"
		| "Plymouth"
		| "RD&E"
		| "Somerset"
		| "Torbay"
		| "Worcestershire"

	/**
	 * The developer handle for this site.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	handle:
		| "bath"
		| "bhrc"
		| "herefordshire"
		| "kernow"
		| "ndht"
		| "plymouth"
		| "rde"
		| "southernderbyshire"
		| "taunton"
		| "torbayandsouthdevon"
		| "worcestershire"

	/**
	 * The URL to this site's Select Role (home) page.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	url: string
}

/**
 * Ensures that a value is a Craft site.
 * @param value The value to check.
 * @returns Whether the value is a Craft site.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftSite = (value: unknown): value is CraftSite =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"id" in value &&
	"name" in value &&
	"handle" in value
