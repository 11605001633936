import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useResolveSectionQuery } from "~/api/client"
import { NEVER_CRAFT_HANDLE } from "~/constants"
import { isCraftSection, type CraftSection } from "~/types/api/craft/models/section"

/**
 * Resolves an individual Craft section from a Craft site handle and Craft section handle.
 * @param params The parameters.
 * @param params.siteHandle The handle of the Craft site to fetch the section within.
 * @param params.sectionHandle The handle of the Craft section to fetch.
 * @param params.skip Whether to skip fetching the Craft section.
 * @returns The Craft entry & API request status.
 * @example const { entry } = useCraftResolveEntry()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftResolveSection = ({
	siteHandle,
	sectionHandle,

	skip = false
}: {
	siteHandle: string | null
	sectionHandle: string | null

	skip?: boolean
}): {
	section: CraftSection | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { data, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useResolveSectionQuery(
		{
			siteHandle: siteHandle ?? NEVER_CRAFT_HANDLE,
			sectionHandle: sectionHandle ?? NEVER_CRAFT_HANDLE
		},
		{
			skip: skip || siteHandle === null || sectionHandle === null
		}
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	if (data !== undefined && !isCraftSection(data))
		throw new Error("The resolved entry is not a Craft section! Did we follow the redirect?")

	return {
		section: data ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
