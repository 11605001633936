declare global {
	function gtag(command: string, nameOrValue: unknown, value?: unknown): Promise<void>
}

declare namespace globalThis {
	function gtag(command: string, nameOrValue: unknown, value?: unknown): Promise<void>
}

// https://developers.google.com/tag-platform/gtagjs/reference
// https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced

export const googleTagManager = (command: string, nameOrValue: unknown, value: unknown = undefined): void => {
	// if (!IS_DEPLOYED) return // Don't send analytics during development

	if (!("gtag" in globalThis) || typeof globalThis.gtag !== "function") return

	globalThis
		.gtag(command, nameOrValue, value)
		// eslint-disable-next-line promise/prefer-await-to-then
		.then(() => {
			console.info(
				`Sent Google Tag Manager command '${command}' with name/value '${JSON.stringify(nameOrValue)}' and value '${JSON.stringify(value)}'`
			)
			return
		})
		// eslint-disable-next-line promise/prefer-await-to-then
		.catch((error: unknown) => {
			console.warn(
				`Failed to send Google Tag Manager command '${command}' with name/value '${JSON.stringify(nameOrValue)}' and value '${JSON.stringify(value)}'! (${error?.toString() ?? "N/A"})`
			)
		})
}
