import { motion, type HTMLMotionProps } from "framer-motion"
import { useCallback, useEffect, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"

import BackgroundImage from "~/components/background-image"
import ContentCard from "~/components/content-card"
import InnerError from "~/components/inner-error"
import Metadata from "~/components/metadata"
import SkeletonLoader from "~/components/skeleton-loader"
import Button from "~/components/standard/button"
import Image from "~/components/standard/image"
import { getConsentMode } from "~/helpers/consent-mode"
import { orNull } from "~/helpers/primitives"
import { useCraftHomeEntry } from "~/hooks/craft/use-craft-home-entry"
import { useRouteCraftSite } from "~/hooks/router/use-route-craft-site"
import { useBreadcrumbsDispatch } from "~/hooks/use-breadcrumbs"
import { useMediaQueries } from "~/hooks/use-media-query"
import { BackgroundImagePath } from "~/images"
import { Routes } from "~/router/routes"
import { isCraftEntryLink, type CraftEntryLinkTarget } from "~/types/api/craft/link"
import type { CraftEntryState } from "~/types/state"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

const RoleButton = ({
	target,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "onClick" | "role" | "type"> & {
	target?: CraftEntryLinkTarget
}): JSX.Element => {
	const navigate = useNavigate()

	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (target === undefined) {
			console.warn("No target was provided for the role button.")
			return
		}

		navigate(target.url, {
			state: {
				target
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, target])

	return (
		<Button
			{...props}
			onClick={onClick}
			className={`items-center gap-x-4 rounded-xl p-4 text-left text-lg ${target !== undefined ? "bg-logo-purple text-white hover:bg-purple-alt active:bg-purple-alt/95" : "bg-control-disabled-background text-control-disabled-text"} ${props.className ?? ""}`.trimEnd()}
		/>
	)
}

/**
 * The page for selecting a role.
 * This is the 2nd step after selecting an NHS trust.
 * @returns The React component. This should only be used by the router.
 * @example <SelectRolePage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const SelectRolePage = ({ ...props }: HTMLMotionProps<"main">): JSX.Element => {
	const { isLandscape } = useMediaQueries()

	const craftSite = useRouteCraftSite()

	const {
		entry: homeEntry,
		isReady: isHomeReady,
		isError: isHomeError,
		error: homeError
	} = useCraftHomeEntry({
		siteId: craftSite?.id ?? null
	})

	// Reset the breadcrumbs when we land on this page
	const { updateFurthestDropDownIndex: setFurthestDropDownIndex } = useBreadcrumbsDispatch()
	useEffect(() => {
		setFurthestDropDownIndex(0)
	}, [setFurthestDropDownIndex])

	// Require consent
	const navigate = useNavigate()
	useEffect(() => {
		if (getConsentMode() === null) navigate(Routes.ConsentMode)
	}, [navigate])

	// Don't render the page if we're not ready
	if (craftSite === null) return <></>

	return (
		<motion.main
			{...props}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={`flex flex-grow flex-col justify-end ${props.className ?? ""}`.trimEnd()}>
			{/* eslint-disable-next-line no-nested-ternary */}
			{isHomeReady && homeEntry === null ? (
				<InnerError
					heading="Not Found"
					message="Sorry, this page does not exist. Please return to the previous page."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ParentReadingToChild}
							accessibilityDescription="A parent reading a book to their child."
						/>
					}
				/>
			) : isHomeError && homeError !== null ? (
				<InnerError
					heading="API Error"
					message="Sorry, we're having problems communicating with our server. Please try again later."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ParentReadingToChild}
							accessibilityDescription="A parent reading a book to their child."
						/>
					}
					error={homeError}
				/>
			) : (
				<>
					<Metadata title="Select Role" path={craftSite.url} />

					{/* Feature */}
					{/* TODO: Try entry's headerImage first, then fallback to a static image! */}
					<BackgroundImage
						imageUrl={BackgroundImagePath.ParentReadingToChild}
						accessibilityDescription="A parent reading a book to their child."
					/>

					{/* Card */}
					<ContentCard heading="What is your role?" isFloating={true}>
						<SkeletonLoader
							rowCount={VITE_HANDI_ROLE_BUTTON_COUNTS[craftSite.handle] ?? 3}
							isLoading={!isHomeReady || homeEntry === null}
							innerClassName={isLandscape === true ? "gap-x-4 flex-row" : "gap-y-4 flex-col"}
							rowClassName="h-20">
							{homeEntry?.fields.roleButtons.map((roleButton, index) => (
								<RoleButton
									key={index}
									label={roleButton.link.text ?? "N/A"}
									target={
										isCraftEntryLink(roleButton.link)
											? (roleButton.link.target ?? undefined)
											: undefined
									}
									className={isLandscape === true ? "flex-1" : undefined}
									icon={
										// eslint-disable-next-line no-nested-ternary
										roleButton.icon?.url.endsWith(".svg") === true ? (
											<ReactSVG
												src={`${UPLOADS_BASE_URL}${roleButton.icon.url}`}
												className="aspect-square h-12 min-h-12 w-12 min-w-12 fill-white"
											/>
										) : roleButton.icon !== null ? (
											<Image
												src={`${UPLOADS_BASE_URL}${roleButton.icon.url}`}
												alt={roleButton.icon.description ?? roleButton.link.text ?? "N/A"}
												className="aspect-square h-12 min-h-12 w-12 min-w-12"
											/>
										) : undefined
									}
								/>
							))}
						</SkeletonLoader>
					</ContentCard>
				</>
			)}
		</motion.main>
	)
}

export default SelectRolePage
