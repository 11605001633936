import { type CraftContentBlockBase, CraftContentBlockType } from "~/types/api/craft/fields/content-blocks"

/**
 * The locations for a Craft map content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export enum CraftMapContentBlockLocation {
	Dentists = 1,
	Doctors = 2,
	HealthProfessionals = 3,
	Hospitals = 4
}

/**
 * Represents a Craft map content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export interface CraftMapContentBlock extends CraftContentBlockBase {
	type: CraftContentBlockType.Map

	/**
	 * The heading of this map block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	heading: string | null

	/**
	 * The type of location to find the closest facilities for.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	location: CraftMapContentBlockLocation
}

/**
 * Ensures that a value is a Craft map content block.
 * @param value The value to check.
 * @returns Whether the value is a Craft map content block.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const isCraftMapContentBlock = (value: unknown): value is CraftMapContentBlock =>
	value !== null &&
	value !== undefined &&
	typeof value === "object" &&
	"type" in value &&
	typeof value.type === "number" &&
	value.type === CraftContentBlockType.Map.valueOf() &&
	"heading" in value &&
	"location" in value
