import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import type { CraftAboutEntry } from "~/types/api/craft/sections/about"
import type { CraftHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftProfessionalsEntry } from "~/types/api/craft/sections/professionals"

/**
 * The navigation breadcrumbs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.6
 */
export interface Breadcrumbs {
	/**
	 * The current/active Craft entry.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.5.0
	 */
	craftEntry: CraftAboutEntry | CraftHospitalEntry | CraftParentsEntry | CraftProfessionalsEntry | null

	/**
	 * The index of the furthest dropdown in the breadcrumbs.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.7
	 */
	furthestDropDownIndex: number
}

/**
 * A Redux slice for storing breadcrumbs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.6
 */
export const slice = createSlice({
	name: "breadcrumbs",
	initialState: {
		craftEntry: null,
		furthestDropDownIndex: 0 // Only show the first
	} satisfies Breadcrumbs as Breadcrumbs,
	reducers: {
		setCraftEntry: (state, action: PayloadAction<Breadcrumbs["craftEntry"] | null>): Breadcrumbs => ({
			...state,
			craftEntry: action.payload
		}),

		setFurthestDropDownIndex: (
			state,
			action: PayloadAction<Breadcrumbs["furthestDropDownIndex"]>
		): Breadcrumbs => ({
			...state,
			furthestDropDownIndex: action.payload
		})
	}
})

/**
 * Sets the current/active Craft entry in the breadcrumbs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const { setCraftEntry } = slice.actions

/**
 * Sets the index of the furthest dropdown in the breadcrumbs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.7
 */
export const { setFurthestDropDownIndex } = slice.actions

/**
 * The slice's reducer for the breadcrumbs.
 * This should be added to the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.6
 */
export default slice.reducer
