import type { ComponentPropsWithRef, JSX } from "react"
import { orNull } from "~/helpers/primitives"

import { CraftTextContentBlockStyle, type CraftTextContentBlock } from "~/types/api/craft/fields/content-blocks/text"
import { CraftSeverity } from "~/types/api/craft/fields/severity"

// Ensure the relevant environment variables are set - https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const BASE_URL = orNull(import.meta.env.VITE_BASE_URL)
if (BASE_URL === null) throw new Error("The environment variable 'VITE_BASE_URL' is empty!")
const API_BASE_URL = orNull(import.meta.env.VITE_API_BASE_URL)
if (API_BASE_URL === null) throw new Error("The environment variable 'VITE_API_BASE_URL' is empty!")
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

// For parsing HTML on Redactor fields
const parser = new DOMParser()

// For parsing inline Redactor links
const redactorInlineLinkPattern = /^{(?<type>entry|asset):(?<id>\d+)@(?<siteId>\d+):url\|\|(?<url>.+)}$/

/**
 * A content block that renders text.
 * @returns A React component.
 * @example <TextContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const TextContentBlock = ({
	block,
	severity,
	heading,

	removeForbiddenTags = false,
	fixLinks = true,
	trimLinkWhitespace = true,
	trimEmptyParagraphs = true,
	removeLeadingHeadings = true,
	trimLonelyBreaks = true,
	removeImages = true,
	trimEmptyTags = true,
	fixSentences = false,
	fixSpecialCharacters = false,

	...props
}: ComponentPropsWithRef<"div"> & {
	block: CraftTextContentBlock
	severity?: CraftSeverity
	heading?: string

	removeForbiddenTags?: boolean
	fixLinks?: boolean
	trimLinkWhitespace?: boolean
	trimEmptyParagraphs?: boolean
	removeLeadingHeadings?: boolean
	trimLonelyBreaks?: boolean
	removeImages?: boolean
	trimEmptyTags?: boolean
	fixSentences?: boolean
	fixSpecialCharacters?: boolean
}): JSX.Element => {
	const html = parser.parseFromString(block.html, "text/html").body

	// Remove forbidden tags
	if (removeForbiddenTags) {
		const forbiddenTags = html.querySelectorAll("script,style,head,iframe,meta,link")
		forbiddenTags.forEach(tag => {
			tag.remove()
			console.warn(`Removed forbidden HTML tag <${tag.tagName.toLowerCase()}>: '${tag.innerHTML}'`)
		})
	}

	// Fix up links
	if (fixLinks) {
		const anchorTags = html.querySelectorAll("a")
		anchorTags.forEach(link => {
			// Convert raw inline Redactor links to usable URLs
			const inlineRedactorLinkMatch = link.getAttribute("href")?.match(redactorInlineLinkPattern)
			if (inlineRedactorLinkMatch !== null && inlineRedactorLinkMatch !== undefined) {
				const type = inlineRedactorLinkMatch.groups?.["type"]
				const _id = inlineRedactorLinkMatch.groups?.["id"]
				const _siteId = inlineRedactorLinkMatch.groups?.["siteId"]
				const url = inlineRedactorLinkMatch.groups?.["url"]
				if (type === undefined || _id === undefined || _siteId === undefined || url === undefined)
					throw new Error("Unable to parse inline Redactor link!")

				const id = parseInt(_id, 10)
				const siteId = parseInt(_siteId, 10)
				if (isNaN(id) || isNaN(siteId))
					throw new Error("Unable to parse numeric IDs within inline Redactor link!")

				if (type !== "entry" && type !== "asset") throw new Error("Unknown inline Redactor link type!")

				link.setAttribute("href", url)
			}

			// Discard referrer information (for security)
			link.setAttribute("rel", "noopener noreferrer")

			// Don't allow Craft CMS URLs to make their way into the content (can happen with Redactor fields as Craft expects a headful site)
			// This should work if when using block.text instead of block.raw too!
			const currentHyperlink1 = link.getAttribute("href")
			if (currentHyperlink1 !== null) {
				const currentURL = new URL(currentHyperlink1, UPLOADS_BASE_URL)
				if (currentURL.pathname.startsWith("/uploads/")) link.setAttribute("href", currentURL.toString())
				else link.setAttribute("href", currentURL.pathname)
			}

			// Open external links in a new tab (for security)
			const currentHyperlink2 = link.getAttribute("href")
			if (currentHyperlink2 !== null && new URL(currentHyperlink2, BASE_URL).origin !== BASE_URL)
				link.setAttribute("target", "_blank")

			// Trim extra spaces
			if (trimLinkWhitespace) link.innerText = link.innerText.trim()

			console.info(`Fixed HTML <a> tag '${link.href}'.`)
		})
	}

	// Remove all paragraph tags with only br tags in them
	if (trimEmptyParagraphs) {
		const paragraphTags = html.querySelectorAll("p")
		paragraphTags.forEach(tag => {
			if (tag.innerHTML === "<br>") {
				tag.remove()
				console.warn(`Removed blank HTML <${tag.tagName.toLowerCase()}> tag!`)
			}
		})
	}

	// Remove leading headings with the same text as the page title
	if (removeLeadingHeadings && heading !== undefined) {
		const ourHeading = heading.toLowerCase().trim()
		const headingTags = html.querySelectorAll<HTMLHeadingElement>("h1:first-child")

		headingTags.forEach(tag => {
			if (tag.innerText.toLowerCase().trim() !== ourHeading) return

			tag.remove()
			console.warn(
				`Removed leading HTML <${tag.tagName.toLowerCase()}> tag with the same text as the page title!`
			)
		})
	}

	// Trim leading/trailing <br> tags
	if (trimLonelyBreaks) {
		const breakTags = html.querySelectorAll("br")
		if (breakTags.length > 0) {
			if (breakTags[0]?.previousSibling === null) {
				breakTags[0].remove()
				console.warn("Removed leading HTML <br> tag!")
			}

			if (breakTags[breakTags.length - 1]?.nextSibling === null) {
				breakTags[breakTags.length - 1]?.remove()
				console.warn("Removed trailing HTML <br> tag!")
			}
		}
	}

	// Remove images/figures, they should be in their own block type
	if (removeImages) {
		const imageTags = html.querySelectorAll("img,figure")
		imageTags.forEach(tag => {
			tag.remove()
			console.warn(`Removed image HTML tag <${tag.tagName.toLowerCase()}>!`)
		})
	}

	// Remove all empty tags (excluding self-closing tags)
	if (trimEmptyTags) {
		const emptyTags = html.querySelectorAll(":empty:not(br,img,hr)")
		emptyTags.forEach(tag => {
			tag.remove()
			console.warn(`Removed empty HTML tag <${tag.tagName.toLowerCase()}>!`)
		})
	}

	// Ensure all paragraphs start with a capital letter and end with a full stop (unless there's other punctuation)
	if (fixSentences) {
		const paragraphs = html.querySelectorAll("p")
		paragraphs.forEach(paragraph => {
			const text = paragraph.innerHTML.trim()
			if (text === "") return

			const firstLetter = text[0]?.toUpperCase()
			if (firstLetter === undefined) return

			const lastCharacter = text[text.length - 1]
			if (lastCharacter !== "." && lastCharacter !== "!" && lastCharacter !== "?" && lastCharacter !== ";") {
				paragraph.innerHTML = `${firstLetter}${text.slice(1)}.`
			} else {
				paragraph.innerHTML = `${firstLetter}${text.slice(1)}`
			}
		})
	}

	// Replace special characters with their HTML entities
	if (fixSpecialCharacters) {
		const text = html.querySelectorAll<HTMLLIElement | HTMLParagraphElement>("p,li")
		text.forEach(tag => {
			tag.innerText = tag.innerText.replace(/'+/g, "&rsquo;")
			tag.innerText = tag.innerText.replace(/&/g, "&amp;")
			tag.innerText = tag.innerText.replace(/</g, "&lt;")
			tag.innerText = tag.innerText.replace(/>/g, "&gt;")
		})
	}

	// Skip if this is an empty block
	if (html.innerHTML === "") {
		console.warn("Not mounting text content block with no HTML!")
		return <></>
	}

	return (
		<div
			{...props}
			// eslint-disable-next-line no-nested-ternary
			className={`content-block ${severity === CraftSeverity.Red ? "page-style-red" : severity === CraftSeverity.Amber ? "page-style-amber" : severity === CraftSeverity.Green ? "page-style-green" : ""} ${block.style === CraftTextContentBlockStyle.Red ? "rounded-2xl bg-red-100 p-3 px-4 text-gray-600" : block.style === CraftTextContentBlockStyle.Amber ? "rounded-2xl bg-orange-100 p-3 px-4 text-gray-600" : block.style === CraftTextContentBlockStyle.Green ? "rounded-2xl bg-green-200 p-3 px-4 text-gray-600" : "bg-inherit"} ${props.className ?? ""}`.trimEnd()}
			dangerouslySetInnerHTML={{
				__html: html.innerHTML
			}}
		/>
	)
}

export default TextContentBlock
