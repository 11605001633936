import { motion, type HTMLMotionProps } from "framer-motion"
import { useLayoutEffect, useRef, type ComponentPropsWithRef, type JSX } from "react"

import { useMediaQueries } from "~/hooks/use-media-query"
import { setContentCardVerticalOffset } from "~/state/slices/layout"
import { useReduxDispatch } from "~/state/store"
import { CraftSeverity } from "~/types/api/craft/fields/severity"
import type { EmptyCallback } from "~/types/components/empty"

/**
 * The component for rendering page content.
 * @param params The component parameters.
 * @param params.heading The heading at the top of the card.
 * @param params.icon The icon to render in the heading.
 * @param params.severity The severity of this page (red/amber/green).
 * @param params.isFloating Whether the card should touch the bottom of the viewport.
 * @param params.constrainDimensions Whether to apply a minimum and maximum height to the card.
 * @param params.dynamicBackgroundImage Whether to update the background image's vertical offset when content changes.
 * @param params.innerClassName The classes for the inner <div />.
 * @param params.children The content to render inside the card.
 * @returns The React component.
 * @example <ContentCard />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ContentCard = ({
	heading,
	icon,

	severity = null,

	isFloating = false,
	constrainDimensions = false,
	dynamicBackgroundImage = true,

	innerClassName,

	children,
	...props
}: Omit<HTMLMotionProps<"div">, "children" | "id"> &
	Pick<ComponentPropsWithRef<"div">, "children"> & {
		heading: string
		icon?: EmptyCallback<JSX.Element> | JSX.Element

		severity?: CraftSeverity | null

		innerClassName?: string

		isFloating?: boolean
		constrainDimensions?: boolean
		dynamicBackgroundImage?: boolean
	}): JSX.Element => {
	const dispatch = useReduxDispatch()

	const { isLandscape } = useMediaQueries()

	// Update the background image's vertical offset when content changes
	const reference = useRef<HTMLDivElement>(null)
	useLayoutEffect(() => {
		if (reference.current === null) return
		dispatch(setContentCardVerticalOffset(dynamicBackgroundImage ? reference.current.offsetTop : null))
	}, [dispatch, reference, dynamicBackgroundImage, children])

	return (
		<motion.div
			{...props}
			ref={reference}
			className={`mx-4 flex flex-col overflow-y-auto shadow-lg transition-all duration-ui ${isLandscape === true ? "flex-grow" : "flex-shrink"} ${constrainDimensions ? "max-h-[80dvh] min-h-[50dvh]" : ""} ${isFloating ? "mb-4 rounded-b-2xl" : ""} ${props.className ?? ""}`.trimEnd()}>
			{/* Heading */}
			<div
				// eslint-disable-next-line no-nested-ternary
				className={`flex flex-row items-center justify-center gap-x-4 rounded-t-2xl px-4 py-2 transition-all ${severity === CraftSeverity.Red ? "bg-algorithm-red" : severity === CraftSeverity.Amber ? "bg-algorithm-amber" : severity === CraftSeverity.Green ? "bg-algorithm-green" : "bg-logo-purple"}`.trimEnd()}>
				{icon !== undefined && (
					<div className="flex aspect-square max-h-8 min-h-8 min-w-8 max-w-8 flex-1 flex-col justify-center">
						<div className="flex flex-col">{typeof icon === "function" ? icon() : icon}</div>
					</div>
				)}
				<h1 className="my-2 mt-3 text-2xl font-black text-white">{heading}</h1>
			</div>

			{/* Content */}
			<div
				// eslint-disable-next-line no-nested-ternary
				className={`flex flex-col gap-y-4 overflow-y-auto bg-white p-4 transition-all ${severity === CraftSeverity.Red ? "text-algorithm-red" : severity === CraftSeverity.Amber ? "text-algorithm-amber" : severity === CraftSeverity.Green ? "text-algorithm-green" : "text-logo-purple"} ${isFloating ? "rounded-b-2xl" : ""} flex-grow ${innerClassName ?? ""}`.trimEnd()}>
				{children}
			</div>
		</motion.div>
	)
}

export default ContentCard
