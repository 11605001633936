import { motion, type HTMLMotionProps } from "framer-motion"
import { useCallback, useEffect, type ComponentPropsWithoutRef, type JSX } from "react"
import { useNavigate } from "react-router-dom"

import BackgroundImage from "~/components/background-image"
import ContentCard from "~/components/content-card"
import Metadata from "~/components/metadata"
import Button from "~/components/standard/button"
import { initializeFirebaseAnalytics } from "~/helpers/analytics"
import { getConsentMode } from "~/helpers/consent-mode"
import { BackgroundImagePath } from "~/images"
import { Routes } from "~/router/routes"

const ConsentModeButton = ({ ...props }: ComponentPropsWithoutRef<typeof Button>): JSX.Element => (
	<Button
		{...props}
		className={`text-logo min-h-12 flex-1 items-center justify-center rounded-2xl bg-logo-purple p-2 font-bold text-white duration-hover hover:bg-purple-alt active:bg-purple-alt/95 ${props.className ?? ""}`.trimEnd()}
	/>
)

const ConsentModePage = ({ ...props }: HTMLMotionProps<"main">): JSX.Element => {
	const navigate = useNavigate()

	const onDeclineClick = useCallback(() => {
		navigate(Routes.SelectTrust)
	}, [navigate])

	const onAcceptClick = useCallback(() => {
		initializeFirebaseAnalytics()

		navigate(Routes.SelectTrust)
	}, [navigate])

	// Navigate away if we've already made our choice
	useEffect(() => {
		if (getConsentMode() !== null || globalThis.appTrackingTransparencyAuthorizationStatus !== undefined)
			navigate(Routes.SelectTrust)
	}, [navigate])

	return (
		<motion.main
			{...props}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={`flex flex-grow flex-col justify-end overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
			<Metadata title="Consent Mode" path={Routes.ConsentMode} />

			<BackgroundImage
				imageUrl={BackgroundImagePath.HandPaintingChild}
				accessibilityDescription="A child with paint on their hands."
			/>

			<ContentCard heading="Consent to tracking" constrainDimensions={true}>
				<p>
					I consent to basic information tracking. No personally identifiable information is stored or
					tracked, all data is aggregated.
				</p>
				<p>
					For example we track how many people who use the app identify as Parents or select a specific
					hospital but not who they are.
				</p>

				<div className="flex flex-row justify-between gap-x-4">
					<ConsentModeButton label="Decline" onClick={onDeclineClick} />
					<ConsentModeButton label="Accept" onClick={onAcceptClick} />
				</div>
			</ContentCard>
		</motion.main>
	)
}

export default ConsentModePage
