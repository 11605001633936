import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useFetchSectionsQuery } from "~/api/client"
import { NEVER_CRAFT_ID } from "~/constants"
import type { CraftSection } from "~/types/api/craft/models/section"

/**
 * Fetches all Craft sections.
 * @param params The parameters.
 * @param params.siteId The identifier of the Craft site to fetch sections within.
 * @param params.skip Whether to skip fetching Craft sections.
 * @returns The sections & API request status.
 * @example const { sections } = useCraftSections()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftSections = ({
	siteId,

	skip = false
}: {
	siteId: number | null

	skip?: boolean
}): {
	sections: CraftSection[] | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { data, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useFetchSectionsQuery(
		{
			siteId: siteId ?? NEVER_CRAFT_ID
		},
		{
			skip: skip || siteId === null
		}
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	return {
		sections: data ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
