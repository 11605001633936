import type { ComponentPropsWithRef, JSX } from "react"

import { orNull } from "~/helpers/primitives"
import type { CraftIconsContentBlock } from "~/types/api/craft/fields/content-blocks/icons"
import type { Unpack } from "~/types/helpers"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

const Icon = ({
	icon,

	...props
}: ComponentPropsWithRef<"div"> & { icon: Unpack<CraftIconsContentBlock["icons"]> }): JSX.Element => (
	<div {...props} className={`flex flex-row items-center gap-x-3 ${props.className ?? ""}`.trimEnd()}>
		{icon.asset !== null ? (
			<div className="h-[48px] max-h-[48px] min-h-[48px] w-[48px] min-w-[48px] max-w-[48px] overflow-visible rounded-full bg-purple-alt/15 p-2">
				<img
					src={`${UPLOADS_BASE_URL}${icon.asset.url}`}
					alt={icon.asset.description ?? icon.label}
					width={48}
					height={48}
					className="aspect-square object-contain"
				/>
			</div>
		) : (
			<div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-alt/15 text-2xl">?</div>
		)}

		<p>{icon.label}</p>
	</div>
)

/**
 * A content block that renders icons.
 * @returns A React component.
 * @example <IconsContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const IconsContentBlock = ({
	block,
	...props
}: ComponentPropsWithRef<"div"> & { block: CraftIconsContentBlock }): JSX.Element => (
	<div {...props} className={`flex flex-col gap-y-3 ${props.className ?? ""}`.trimEnd()}>
		{block.icons.map((icon, index) => (
			<Icon key={index} icon={icon} />
		))}
	</div>
)

export default IconsContentBlock
