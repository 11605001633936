import { captureMessage } from "@sentry/react"
import { useEffect, type ComponentPropsWithRef, type JSX } from "react"
import { orNull } from "~/helpers/primitives"

import type { CraftMultipleImagesContentBlock } from "~/types/api/craft/fields/content-blocks/multiple-images"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

/**
 * A content block that renders multiple images.
 * @returns A React component.
 * @example <ImagesContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ImagesContentBlock = ({
	block,
	...props
}: ComponentPropsWithRef<"div"> & { block: CraftMultipleImagesContentBlock }): JSX.Element => {
	useEffect(() => {
		captureMessage(`Image content block not implemented yet!`, {
			level: "info",
			extra: { ...block }
		})
	})

	return (
		<div {...props}>
			<p className="font-mono text-logo-magenta">TODO: Images content block ({block.images.length})</p>
		</div>
	)
}

export default ImagesContentBlock
