import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { orNull } from "~/helpers/primitives"
import type { CraftLivePreview } from "~/types/api/craft/live-preview"

/**
 * Parses the Craft live preview parameters from the URL.
 * @returns The Craft live preview parameters, or undefined if they are not present.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftLivePreview = (): CraftLivePreview | undefined => {
	const [searchParameters] = useSearchParams()

	const identifier = useMemo<string | null>(
		() => orNull(searchParameters.get("x-craft-live-preview")),
		[searchParameters]
	)
	const token = useMemo<string | null>(() => orNull(searchParameters.get("token")), [searchParameters])

	if (identifier === null || token === null) return undefined

	return {
		identifier,
		token
	}
}
