import type { ComponentPropsWithRef, JSX } from "react"

import Image from "~/components/standard/image"
import { orNull } from "~/helpers/primitives"
import {
	CraftSingleImageContentBlockSize,
	craftSingleImageContentBlockSizeToPercentage,
	type CraftSingleImageContentBlock
} from "~/types/api/craft/fields/content-blocks/single-image"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

/**
 * A content block that renders a single image.
 * @returns A React component.
 * @example <ImageContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ImageContentBlock = ({
	block,

	...props
}: ComponentPropsWithRef<"img"> & { block: CraftSingleImageContentBlock }): JSX.Element => {
	if (block.asset === null) return <></>

	if (block.size === CraftSingleImageContentBlockSize.Half)
		return (
			<div className="flex flex-row gap-x-4" data-requires-merge={true}>
				<Image
					{...props}
					src={`${UPLOADS_BASE_URL}${block.asset.url}`}
					alt={block.asset.description ?? undefined}
					style={{
						width: craftSingleImageContentBlockSizeToPercentage(block.size)
					}}
				/>
			</div>
		)

	return (
		<Image
			{...props}
			src={`${UPLOADS_BASE_URL}${block.asset.url}`}
			alt={block.asset.description ?? undefined}
			className="m-auto"
			style={{
				width: craftSingleImageContentBlockSizeToPercentage(block.size)
			}}
		/>
	)
}

export default ImageContentBlock
