import { useCallback, useEffect, useMemo, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"

import { orNull } from "~/helpers/primitives"
import { useRouteCraftSite } from "~/hooks/router/use-route-craft-site"
import { useBreadcrumbs, useBreadcrumbsDispatch, useFurthestDropDownIndexSelection } from "~/hooks/use-breadcrumbs"
import { setBreadcrumbsVisibility } from "~/state/slices/layout"
import { useReduxDispatch } from "~/state/store"
import { CraftSeverity } from "~/types/api/craft/fields/severity"
import type { CraftPartialAboutEntry } from "~/types/api/craft/sections/about"
import type { CraftPartialHospitalEntry } from "~/types/api/craft/sections/hospitals"
import type { CraftPartialParentsEntry } from "~/types/api/craft/sections/parents"
import type { CraftPartialProfessionalsEntry } from "~/types/api/craft/sections/professionals"
import type { CraftEntryState } from "~/types/state"

import ChevronIcon from "~/assets/icons/chevron.svg?react"
import TickIcon from "~/assets/icons/tick.svg?react"
import WarningIcon from "~/assets/icons/warning.svg?react"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

type BreadcrumbEntryTypes =
	| CraftPartialAboutEntry
	| CraftPartialHospitalEntry
	| CraftPartialParentsEntry
	| CraftPartialProfessionalsEntry

const getBreadcrumbIcon = ({
	entry,
	currentIndex
}: {
	entry: BreadcrumbEntryTypes
	currentIndex: number
}): JSX.Element | undefined => {
	if (entry.fields.icon?.url.endsWith(".svg") === true)
		return (
			<ReactSVG
				src={`${UPLOADS_BASE_URL}${entry.fields.icon.url}`}
				// eslint-disable-next-line no-nested-ternary
				className={`w-full ${entry.fields.severity === CraftSeverity.Red ? "fill-algorithm-red" : entry.fields.severity === CraftSeverity.Amber ? "fill-algorithm-amber" : entry.fields.severity === CraftSeverity.Green ? "fill-algorithm-green" : currentIndex === 0 ? "fill-white" : "fill-logo-purple"}`.trimEnd()}
			/>
		)

	if (entry.fields.severity === CraftSeverity.Red) return <WarningIcon className="h-full w-full fill-algorithm-red" />
	if (entry.fields.severity === CraftSeverity.Amber)
		return <WarningIcon className="h-full w-full fill-algorithm-amber" />
	if (entry.fields.severity === CraftSeverity.Green)
		return <TickIcon className="h-full w-full fill-algorithm-green" />

	return undefined
}

const Breadcrumb = ({
	entry,

	currentIndex,
	maximumIndex,

	...props
}: ComponentPropsWithRef<"div"> & {
	entry: BreadcrumbEntryTypes

	currentIndex: number
	maximumIndex: number
}): JSX.Element => {
	const navigate = useNavigate()

	const furthestDropDownIndex = useFurthestDropDownIndexSelection()
	const { updateFurthestDropDownIndex } = useBreadcrumbsDispatch()

	const isExpanded = useMemo<boolean>(
		() => currentIndex < furthestDropDownIndex,
		[currentIndex, furthestDropDownIndex]
	)
	const remainingBreadcrumbs = useMemo<number>(() => maximumIndex - currentIndex, [currentIndex, maximumIndex])

	const craftSite = useRouteCraftSite()

	const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
		if (craftSite === null) {
			console.warn("No Craft site selection yet!")
			return
		}

		navigate(entry.url, {
			state: {
				target: {
					site: craftSite.id,
					section: entry.section,
					entry: entry.id,
					url: entry.url
				}
			} satisfies CraftEntryState as CraftEntryState
		})

		// Collapse all drop-downs
		updateFurthestDropDownIndex(0)
	}, [navigate, updateFurthestDropDownIndex, craftSite, entry])

	const onChevronClick = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
		updateFurthestDropDownIndex(isExpanded ? currentIndex : currentIndex + 1)
	}, [updateFurthestDropDownIndex, isExpanded, currentIndex])

	const breadcrumbIcon = getBreadcrumbIcon({ entry, currentIndex })

	return (
		<div
			// eslint-disable-next-line no-nested-ternary
			className={`flex h-12 flex-col justify-between text-sm transition-all hover:cursor-pointer ${currentIndex > furthestDropDownIndex ? "hidden" : ""} ${furthestDropDownIndex === 0 || maximumIndex === 0 ? "rounded-xl" : currentIndex === 0 ? "rounded-t-xl" : currentIndex === maximumIndex || !isExpanded ? "rounded-b-xl" : ""} ${currentIndex === 0 ? "bg-logo-purple fill-white text-white hover:bg-purple-alt active:bg-purple-alt/95" : entry.fields.severity === CraftSeverity.Red ? "bg-algorithm-red/15 fill-algorithm-red text-algorithm-red hover:bg-algorithm-red/20 active:bg-algorithm-red/25" : entry.fields.severity === CraftSeverity.Amber ? "bg-algorithm-amber/15 fill-algorithm-amber text-algorithm-amber hover:bg-algorithm-amber/20 active:bg-algorithm-amber/25" : entry.fields.severity === CraftSeverity.Green ? "bg-algorithm-green/15 fill-algorithm-green text-algorithm-green hover:bg-algorithm-green/20 active:bg-algorithm-green/25" : "bg-purple-alt/15 fill-logo-purple text-logo-purple hover:bg-purple-alt/25 active:bg-purple-alt/35"} ${props.className ?? ""}`.trimEnd()}>
			{/* Separator */}
			<hr className={`mx-0 border-logo-purple/50 ${currentIndex < 2 ? "invisible" : ""}`.trimEnd()} />

			<div className="flex flex-row justify-between gap-x-2 px-2">
				<div
					onClick={onClick}
					className="flex flex-1 flex-row items-center gap-x-3 overflow-hidden text-ellipsis whitespace-nowrap px-2">
					{/* Icon */}
					{breadcrumbIcon !== undefined && (
						<div className="flex h-6 min-h-6 w-6 min-w-6 flex-col justify-center">
							<div className="flex flex-col">{breadcrumbIcon}</div>
						</div>
					)}

					{/* Title */}
					<span>
						{entry.title}
						{!isExpanded && remainingBreadcrumbs > 0 && (
							<span className="opacity-75">, and {remainingBreadcrumbs.toString()} more...</span>
						)}
					</span>
				</div>

				{/* Drop-down */}
				{currentIndex < maximumIndex && (
					<div
						onClick={onChevronClick}
						// eslint-disable-next-line no-nested-ternary
						className={`flex flex-row rounded-full p-2 ${currentIndex === 0 ? "hover:bg-control-hover/15" : entry.fields.severity === CraftSeverity.Red ? "hover:bg-algorithm-red/10" : entry.fields.severity === CraftSeverity.Amber ? "hover:bg-algorithm-amber/10" : entry.fields.severity === CraftSeverity.Green ? "hover:bg-algorithm-green/10" : "hover:bg-purple-alt/10"}`.trimEnd()}>
						<ChevronIcon
							width={16}
							height={16}
							className={`aspect-square rounded-full transition-transform ${isExpanded ? "-rotate-90" : "rotate-180"} ${currentIndex === 0 ? "fill-white hover:fill-white/90 active:fill-white/80" : "fill-logo-purple hover:fill-purple-alt active:fill-purple-alt/95"}`}
						/>
					</div>
				)}
			</div>

			{/* Just for equal spacing */}
			<hr className="invisible mx-0" />
		</div>
	)
}

/**
 * The collapsible breadcrumbs.
 * @returns The React component.
 * @example <Breadcrumbs />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
const Breadcrumbs = ({ ...props }: ComponentPropsWithRef<"div">): JSX.Element => {
	// Fetch the breadcrumbs for the current entry
	const [breadcrumbs, hasBreadcrumbs] = useBreadcrumbs()

	// Let other components know if we'll be rendering breadcrumbs
	const dispatch = useReduxDispatch()
	useEffect(() => {
		dispatch(setBreadcrumbsVisibility(hasBreadcrumbs))

		return () => {
			dispatch(setBreadcrumbsVisibility(hasBreadcrumbs))
		}
	}, [dispatch, hasBreadcrumbs])

	// Don't bother rendering anything if we haven't got any breadcrumbs
	if (!hasBreadcrumbs) return <></>

	return (
		<div {...props} className={`m-1 flex flex-col ${props.className ?? ""}`.trimEnd()}>
			<div className="flex flex-col">
				{breadcrumbs
					?.slice(0, 1)
					.map((entry, index) => (
						<Breadcrumb
							key={entry.id}
							entry={entry}
							currentIndex={index}
							maximumIndex={breadcrumbs.length - 1}
						/>
					))}
			</div>

			<div className="fixed mt-12 flex w-[calc(100%-3.5rem)] flex-col rounded-b-xl bg-white">
				{breadcrumbs
					?.slice(1)
					.map((entry, index) => (
						<Breadcrumb
							key={entry.id}
							entry={entry}
							currentIndex={index + 1}
							maximumIndex={breadcrumbs.length - 1}
						/>
					))}
			</div>
		</div>
	)
}

export default Breadcrumbs
