import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useResolveSiteQuery } from "~/api/client"
import { NEVER_CRAFT_HANDLE } from "~/constants"
import { isCraftSite, type CraftSite } from "~/types/api/craft/models/site"

/**
 * Resolves an individual Craft site from a Craft site handle.
 * @param params The parameters.
 * @param params.siteHandle The handle of the Craft site to fetch.
 * @param params.skip Whether to skip fetching the Craft section.
 * @returns The Craft entry & API request status.
 * @example const { entry } = useCraftResolveEntry()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.5.0
 */
export const useCraftResolveSite = ({
	siteHandle,

	skip = false
}: {
	siteHandle: string | null

	skip?: boolean
}): {
	site: CraftSite | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { data, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useResolveSiteQuery(
		{
			siteHandle: siteHandle ?? NEVER_CRAFT_HANDLE
		},
		{
			skip: skip || siteHandle === null
		}
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	if (data !== undefined && !isCraftSite(data))
		throw new Error("The resolved entry is not a Craft section! Did we follow the redirect?")

	return {
		site: data ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
